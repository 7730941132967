import { Button, Card } from 'react-bootstrap'
import React from 'react'
import { useHistory } from 'react-router'
import { AuthContext } from '../../context/Auth/AuthContext'

function Func() {

    const history = useHistory()
    const funcionarios = ["Alisson", "Antônio", "Bartô", "Carlos", "Lucas"]
    const { user, setUser } = React.useContext(AuthContext)
   
    return (
        <React.Fragment>
            {funcionarios.map((f, idx) => {
                return (<Button variant="dark" key={idx} onClick={() => { setUser(f); history.push('/'); localStorage.setItem('funcionario',f) }}>
                    <Card bg='dark' style={{ width: '35rem' }}>
                                               <Card.Body>
                            <Card.Title>{f}</Card.Title>
                           
                        </Card.Body>
                    </Card>


                </Button>)
            })}

        </React.Fragment>

    )
}

export default Func