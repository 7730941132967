import React, { useState, useEffect } from 'react'
import axios from '../../services/api'
import List from '../../components/ListProdutos/index'
import { Table } from 'react-bootstrap'
import Produtos from "../../components/Produtos/index";
import Total, { valorTotal } from '../../components/Total Produtos/TotallProdutos'
import Modal from '../../components/Modal/Modal'
import Obs from '../../components/Modal/ModalObservacao'
import ModalSave from '../../components/Modal/ModalSave'
import { useHistory } from 'react-router'
import ModalCliente from '../../components/Modal/ModalCliente'


function Edit(props) {



  const [tabela, setTabela] = useState([])
  const [obs, setObs] = useState(props.obs)
  const [desconto, setDesconto] = useState(0)
  const [type, setType] = useState('')
  const [cliente, setCliente] = useState([{ nome: "Buscar Cliente:" }])
  const [clienteaxios, setClienteaxios] = useState([])
  const [valorD, setValorD] = useState(0)

  const put = props.location.state.item.id
  const history = useHistory()

  const mudarCliente = (i) => setCliente(i)

  const addclienteaxios = (i) => setClienteaxios(i)

  const addTable = params => setTabela([...tabela, { ...params }])

  const removeTable = (naoremover,indice) => {
    tabela.splice(indice, 1); setTabela([...tabela]);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const type = props.location.state.item.type;
        const response = await (type === "venda"
          ? axios.get(`/vendas/${put}`)
          : axios.get(`/pedidos/${put}`));

        const data = response.data;
        setTabela(type === "venda" ? JSON.parse(data.objetos) : data.objetos);
        setDesconto(data.desconto);
        setObs(data.obs);
        setType(data.type);
        if (data.nomeCliente !== "sem cliente") {
          const responseCliente = await axios.get(`/clientes/${data.cliente}`);
          mudarCliente([responseCliente.data]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [props.location.state.item.type, put]);


  useEffect(async () => {

    if (cliente[0].nome !== "Buscar Cliente:" && cliente[0].nome !== "sem cliente") {

      const objs = []
      const parseCLient = JSON.parse(cliente[0].vendas)

      parseCLient.length === 0 ? setValorD(0) : parseCLient.forEach(async (element, index, array) => {


        await axios.get(`/vendas/${element}`).then(res => {

          objs.push(valorTotal(res.data.objetos) - res.data.desconto)

          if (res.data?.objetos && index + 1 === array.length) {

            const sum = objs.reduce((acc, cur) => { return acc + cur })

            setValorD(sum.toFixed(2))

          }
        })
      })
    }

  }, cliente)

  return (
    <React.Fragment>

      <List produtos={Produtos} addTable={addTable} ></List>
      <Table variant='dark'>
        <tbody>
          {tabela?.length > 0 ? (tabela.map((item, indice) => {

            const {
              quantidade,
              name,
              nameAlfa,
              precoAtual,
              date,
              atendente
            } = item;


            if (tabela[indice].precoAtual === null) {

              const returnpreco = item.preco.filter((params) => params.quantidade[0] <= quantidade)
              const preco = returnpreco[returnpreco.length - 1]
              tabela[indice].precoAtual = preco.quantidade[1]
              setTabela([...tabela])

              return tabela[indice].precoAtual

            }
            function alteraPreco(valor) {
              tabela[indice].precoAtual = parseFloat(valor)
              setTabela([...tabela])

            }


            return (<>
              <tr key={tabela.index}>
                <td> {nameAlfa} || == {name} </td>

                <td> Quant: {quantidade} </td>
                <td><Modal item={item} alteraPreco={alteraPreco}></Modal></td>
                <td> Total: {(precoAtual * parseFloat(quantidade)).toFixed(2)}</td>
                <td> <button className='btn btn-danger' onClick={removeTable.bind(null, item, indice)}>X</button> </td>
              </tr>
              <tr style={{ fontSize: "10px" }}>

                <td>{date}</td>
                <td>{atendente}</td>
              </tr>
            </>
            )
          })) : (<tr></tr>
          )

          }
        </tbody>
      </Table>

      <Total tabela={tabela} desconto={desconto}></Total>
      <Obs obs={obs} setObs={setObs}></Obs>
      <ModalCliente cliente={cliente} mudarCliente={mudarCliente} clienteaxios={clienteaxios} addclienteaxios={addclienteaxios} valorD={valorD}></ModalCliente>&nbsp;&nbsp;
      {cliente[0].nome !== "Buscar Cliente:" ? <h3>{clienteaxios.map((i, indice) => { if (i.nome === cliente[0].nome) { return <b key={indice}>Débito : R$  {valorD} </b> } })}</h3> : <h3></h3>}
      <ModalSave tabela={tabela} obs={obs} history={history} type={type} id={put} desconto={desconto} cliente={cliente}></ModalSave>

    </React.Fragment>
  )
}

export default Edit
