import React, { useState, useRef, useEffect } from 'react';
import { Button, ButtonGroup, Modal, makeStyles, TextField } from '@material-ui/core';

import PrintOptions from '../Impressoes/PrintOptions';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),


    },
    quant: {

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
}));

const QuickServiceModal = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [quantity, setQuantity] = useState('');
    const quantityInputRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);

    };

    useEffect(() => {

        setTimeout(() => {
            if (open && quantityInputRef.current) {

                // Foca o campo de quantidade quando o modal é aberto e o campo está disponível
                quantityInputRef.current.focus();
            }
        }, 300)

    }, [open]);

    const handleQuantityChange = (event) => {
      
        if(parseFloat(event.target.value) > 0 || event.target.value === '' )
        setQuantity(event.target.value);
    };



    const handleOptionSelect = (option) => {
      setSelectedOption(option);
      console.log(option,'option')
      // Aqui você pode fazer algo com a opção selecionada, como enviar para a API de impressão, etc.
    };

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                Impressão Comum
            </Button>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>
                    <div className={classes.quant}>
                        <TextField
                            label="Quantidade"
                            type="number"
                            value={quantity}
                            onChange={handleQuantityChange}
                            inputRef={quantityInputRef}
                            inputProps={{ style: { fontSize: 30, padding: 10, maxWidth: '300px' } }} // Estilo para aumentar o tamanho
                        />

                    </div>
                    
                    <PrintOptions onSelect={handleOptionSelect} quantity={quantity}/>
                    {/*   <Button variant="contained" color="secondary" onClick={handleClose}>
                        Fechar Modal
    </Button>*/}
                </div>
            </Modal>
        </div>
    );
};

export default QuickServiceModal;
