import React, { useEffect, useState } from "react";

import Produtos from '../../components/Produtos/index'
import List from '../../components/ListProdutos/'
import Modal from '../../components/Modal/Modal'
import ModalSave from '../../components/Modal/ModalSave'
import ModalCliente from '../../components/Modal/ModalCliente'
import { Table, Button } from 'react-bootstrap'
import Total, { valorTotal } from '../../components/Total Produtos/TotallProdutos'
import Obs from '../../components/Modal/ModalObservacao'
import axios from '../../services/api'
import { useHistory } from "react-router";
import ToastDemo, { openTime } from "../../components/Toast/Toast";
import QuickServiceModal from '../../components/Modal/QuickServiceModal'

function Pdv() {

  const [tabela, setTabela] = useState([])
  const [obs, setObs] = useState('')
  const [cliente, setCliente] = useState([{ nome: "Buscar Cliente:" }])
  const [clienteaxios, setClienteaxios] = useState([])
  const [valorD, setValorD] = useState(0)
  const [toast, setToast] = useState(0)



  useEffect(async () => {

    if (cliente[0].nome !== "Buscar Cliente:") {

     
      cliente[0].nome.length === 0 ? setValorD(0) : setValorD(cliente[0].debito)
    }

  }, cliente)

  const mudarCliente = (i) => setCliente(i)
  const setarToast = (t) => {
    if (t) { setToast(0) }
    else {
      setTimeout(() => { setToast(0) }, 6000)
    }
  }

  const addTable = params => {
    setTabela([...tabela, { ...params }]);



    if (params.encf && params.quantidade > 10 && params.quantidade < 400) { setToast(params.quantidade); setarToast() }
    if (params.encfv && params.quantidade > 10 && params.quantidade < 400) { setToast(params.quantidade / 2); setarToast() }
  }

  const removeTable = (naoRemover, indice) => { tabela.splice(indice, 1); setTabela([...tabela]) }

  const setarObs = (i) => { setObs(i) }

  const addclienteaxios = (i) => setClienteaxios(i)

  const history = useHistory()




  return (
    <div >
      <QuickServiceModal></QuickServiceModal>
      <List produtos={Produtos} addTable={addTable} ></List>
      
      <Table variant='dark'>
        <tbody>
          {tabela.length > 0 ? (tabela.map((item, indice) => {

            if (tabela[indice].precoAtual === null) {

              const returnpreco = item.preco.filter((params) => params.quantidade[0] <= item.quantidade)
              const preco = returnpreco[returnpreco.length - 1]
              tabela[indice].precoAtual = preco.quantidade[1]
              setTabela([...tabela])
              return tabela[indice].precoAtual

            }
            function alteraPreco(valor) {
              tabela[indice].precoAtual = parseFloat(valor)
              setTabela([...tabela])

            }

            return (
              <>
                <tr key={indice}>
                  <td> {item.nameAlfa} ||= {item.name}
                   
                  </td>
                  <td> Quant: {item.quantidade}</td>
                  <td><Modal item={item} alteraPreco={alteraPreco}></Modal></td>
                  <td> Total: {(item.precoAtual * item.quantidade)}</td>
                  <td> <Button variant='danger' onClick={removeTable.bind(null, item, indice)}>X</Button> </td>
               
                </tr>
                <tr style={{fontSize: "12px"}}>
              
                      {(item.date)}
                </tr>
              </>
            )
          })) : (<tr></tr>
          )

          }
        </tbody>
      </Table>
      <Total tabela={tabela}></Total>
      <br></br>

      <div style={{position: 'relative' ,display: 'flex', justifyContent: 'center', margin: '10px', gap: '10px', bottom: 0}}>
      <Obs obs={obs} setObs={setarObs}></Obs>
      <ModalCliente cliente={cliente} mudarCliente={mudarCliente} clienteaxios={clienteaxios} addclienteaxios={addclienteaxios} valorD={valorD}></ModalCliente>&nbsp;&nbsp;
      {cliente[0].nome !== "Buscar Cliente:" ? <h3>{clienteaxios.map((i, indice) => { if (i.nome === cliente[0].nome) { return <b key={indice}>Débito : R$  {i.debito} </b> } })}</h3> : <h3></h3>}
      </div>
      <ModalSave tabela={tabela} obs={obs} history={history} cliente={cliente}  > </ModalSave>
      {toast !== 0 ? <ToastDemo Produtos={Produtos} addTable={addTable} toast={toast} setarToast={setarToast}></ToastDemo> : <></>}

    </div>
  )
}

export default Pdv