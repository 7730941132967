export function valorTotal(tabela) {
    
    const values = Object.values(tabela).reduce((acc, tabela) => {
        
        return (acc + (tabela.precoAtual * tabela.quantidade))
    }, 0)
    return values.toFixed(2)

}

function Total({ tabela, desconto }) {
   
  
    return (
        <>
            {
                desconto ? 
                <div style={{position: "relative",  bottom: "0%", width: "100%", height: "10%"}}><h4> <p>R$ {valorTotal(tabela)}  - {parseFloat(desconto).toFixed(2)} Desconto</p></h4>
                 <h3 style={{color: '#eee'}}> Total: R$ {(valorTotal(tabela) - desconto).toFixed(2)} com desconto</h3>
                        {console.log((valorTotal(tabela) - desconto))}
                </div>: 
                <h2 style={{position: "relative",  bottom: "10%", width: "50%", height: "0%", color: '#eee'}}>
                        Total: R$ {valorTotal(tabela)}
                    </h2>
            }
    </>
    )
}


export default Total