import { Link, useHistory } from 'react-router-dom'

import { Button, Nav } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { AuthContext } from '../../context/Auth/AuthContext'
import './menu.css'
import socket from '../../services/socket-io'
import Axios from '../../services/api'
import Badge from '@material-ui/core/Badge';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import PaymentIcon from '@material-ui/icons/Payment';

import {Assignment } from '@material-ui/icons';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import MoldIcon from '@material-ui/icons/CropFree'
import sound from '../sounds/sound.mp3'


import SettingsIcon from '@material-ui/icons/Settings';

function Menu({ children }) {
    const { user, handleLogout } = React.useContext(AuthContext)
    const [countPending, setCountPending] = useState(0)
    const [notificationPermission, setNotificationPermission] = useState('');
    const [soundPlayed, setSoundPlayed] = useState(false);

    useEffect(() => {
        // Verificar a permissão de notificação quando o componente for montado
        checkNotificationPermission();
      }, []);


      const checkNotificationPermission = () => {
        if ('Notification' in window) {
          Notification.requestPermission().then(permission => {
            setNotificationPermission(permission);
          });
        }
      };

    const showNotification = (params) => {
        if ('Notification' in window) {
          Notification.requestPermission().then(function(result) {
            if (result === 'granted') {
              const notificacao = new Notification(params[0], {
                body: params[1]
              });

              setTimeout(() => {
                notificacao.close();
              }, 5000)
            }
          });
        }
      }


    const history = useHistory()

    useEffect(async () => {
        if (user?.nome) {
            
            socket().emit('login', user.nome)
            const AtualizaPending = async () => {
               const countPendentes  = await Axios.get(`/ordem?status=${'countPendentes'}`);
               setCountPending(countPendentes.data)
               return countPendentes.data
            }
            AtualizaPending()

            socket().on('ordemCreate', (data) => {
                AtualizaPending()
                showNotification(['Ordem Criada', 'Nova Ordem'])
              });
              socket().on('ordemUpdate', (data) => {
                AtualizaPending()
              });
              socket().on('pixCreate', (data) => {
                const not = [`Pix de R$ ${data.value.toFixed(2)}`, `Pix de ${data.name} valor R$ ${data.value.toFixed(2)}!`]
                showNotification(not)
                if (!soundPlayed) {
                  const audio = new Audio(sound);
                  audio.play();
                  setSoundPlayed(true);
                }
              });
        }
    }, [user])

   



    useEffect(() => {
        const handleKeyDown = (e) => {
            const tecla = e.key
            if (tecla === "F1") { e.preventDefault(); history.push('/') }
            if (tecla === "F3") { e.preventDefault(); history.push('/pdv') }
            if (tecla === "F8") { e.preventDefault(); history.push('/pedidos') }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [history]);

    const returnButton = (nome, location, ico)=>{
        return(
            history.location.pathname === location ? <Button className='mybutton'>{nome} {ico} </Button> : <Button className='mybutton'>    {nome} {ico} </Button>
        )
    }
    const returnNaveLink = (menu, link,icon)=>{

        return(
            <Nav.Item><Link to={link}>{returnButton(menu, link,icon)}</Link></Nav.Item>
        )
    }

    const listaNavegação = [
      {nome: ['Pix', '/pix']},
      {nome: ['Home - F1', '/', <HomeIcon/>]},
      {nome: ['Pdv - F3', '/pdv', <AttachMoneyIcon/>]},
      {nome: ['Ordem', '/ordem', <Assignment/>]},
      {nome: ['Produção ', '/ordemList', <Badge badgeContent={countPending} color="secondary"><SettingsIcon /></Badge>]},
      {nome: ['Pedidos - F8', '/pedidos', <ShoppingCartIcon/>]},
      {nome: ['Clientes', '/clients', <PersonIcon/>]},
      {nome: ['Moldes', '/moldes', <MoldIcon/>]},

    ]
    
    return (
        <div style={{ backgroundColor: '#343A40' }}>
            <Nav className="navbar-dark bg-dark" variant='tabs' style={{ justifyContent: 'center' }}  >
              
              
              
              { listaNavegação.map((e)=> returnNaveLink(e?.nome[0], e?.nome[1], e?.nome[2]))  }


                <Nav.Item><Button variant='secondary' className='mybutton'>{user.nome}</Button></Nav.Item>
                
          
                {user.profile === "admin" && <Nav.Item><Link to='/adm'><Button className='btn btn-secondary mybutton'>Admin</Button></Link></Nav.Item>}
                <Nav.Item>
                    <Button className='mybutton' onClick={() => handleLogout()}>
                        Sair
                    </Button>
                </Nav.Item>
       

            </Nav>
            {children ? children : null}
            <NotificationContainer />
        </div>
    )

}

export default Menu