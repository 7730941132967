import React, { useState, useEffect, useRef } from 'react'

import moment from 'moment/moment'
import 'moment/locale/pt'
import { AuthContext } from '../../context/Auth/AuthContext';
import {
    Modal,
    Paper,
    Button,
    Card,
    CardContent,
    Typography
} from '@material-ui/core';



import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
       
        
    },
    card: {
        minWidth: 275,
        maxWidth: 400,
        margin: '16px',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'space-around', // ou 'space-between' para espaçamento máximo
        flexWrap: 'wrap',
      },
}));



function List(props) {

    const classes = useStyles();

    const [btn, setBtn] = useState({ name: false })
    const [val, setVal] = useState({ name: false })
    const [pesquisa, setPesquisa] = useState('')
    const listFocus = useRef()
    const quantideFocus = useRef()
    const buttonFocus = useRef()
    const [open, setOpen] = useState(false);
    const [objModal, setObjModal] = useState([])


    const { user } = React.useContext(AuthContext)

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setObjModal([]);
    };


    useEffect(() => {

        /* document.onkeydown = (e)=>{if(e.key === 'F3' && val)e.preventDefault()
         
         }*/

        if (pesquisa === '') listFocus.current.focus()
        if (quantideFocus.current) quantideFocus.current.focus()
        //  if (btn.subproduto && btn.subproduto.length !== 1) buttonFocus.current.focus()

    })

    const Limparstates = e => {
        setBtn({ name: false })
        setVal({ name: false })
        e.target.value = ''
    }
    const Valuesearch = value => {

        const objsearch = props.produtos.find(product => product.name === value.target.value)
        setPesquisa(value.target.value)
        if (objsearch) setBtn(objsearch)

    }
    const Buttons = ({ btn }) => {

        return (
            <div>


                {!btn?.modal ? btn?.subproduto?.map((item, index) => {
                    const styleButtons = (style) => {
                        if (style) return { padding: "15px", marginLeft: "3px", marginTop: "3px", margin: "3px", backgroundColor: style }
                        else { return { padding: "15px", marginLeft: "3px", marginTop: "3px", margin: "3px", } }
                    }
                    return (
                        index === 0 ?
                            <Button variant="contained" color='primary' key={index} onClick={AddVal.bind(null, item)} ref={buttonFocus} style={styleButtons(item.style)}>{item.name}</Button>
                            :
                            <Button variant="contained" color='primary' key={index} onClick={AddVal.bind(null, item)} style={styleButtons(item.style)}>{item.name}</Button>

                    )
                })

                    :
                    <div>
                        {btn.modal?.map((i, index) => {
                            return (
                                <Button key={index} variant="contained" style={{ background: '#0085FD', color: 'white', marginLeft: '5px' }}
                                    onClick={
                                        () => {
                                            const btns = btn?.subproduto?.filter((e) => e.modal === i)
                                            setObjModal(btns)
                                            setOpen(true)
                                        }
                                    }>
                                    {i}
                                </Button>
                            )

                        })}

                        <Modal
                            open={open}
                            onClose={handleClose}
                            className={classes.modal}
                        >
                            <Paper className={classes.paper}>
                                
                                 
                                 
                            <div className={classes.cardContainer}>
                                    {objModal && objModal.map((objs, key) => (
                                       
                                            
                                                <Button  variant="contained" color='primary' key={key} style={{ margin: '10px',  height: '100px', width: '150px' }} onClick={AddVal.bind(null, objs)}>
                                                {objs.name}
                                            </Button>
                                           
                                            
                                        
                                    ))}
                              </div>

                                <Button variant="contained" color="secondary" onClick={handleClose} style={{ marginTop: '50px' }}>
                                    Fechar
                                </Button>
                            </Paper>
                        </Modal>
                    </div>
                }
            </div>
        )
    }

    function AddVal(params) {
        if(objModal.length > 0) setObjModal([]); setOpen(false);

        if (!params.button) {
            let mudarbtn = { name: 'teste', subproduto: [params] }

            setBtn(mudarbtn)
        }

        return setVal(params)

    }
    function Val(obj) {


        function Enter(event) {
            if (event.keyCode === 13 && event.target.value > 0) {
                const valordoinput = parseFloat(event.target.value)
                parseFloat(valordoinput)
                obj.val.quantidade = valordoinput
                obj.val.nameAlfa = pesquisa
                obj.val.date = moment().locale('pt').format('LLL');
                obj.val.atendente = user.nome
                props.addTable(obj.val)
            }

        }

        return (
            <div>
                {obj?.val?.name ? (
                    <input type='number' onKeyUp={Enter.bind()} className='form-inline' ref={quantideFocus}></input>
                ) : (<div></div>)

                }
            </div>
        )
    }

    const Moldes = ({ valMoldes }) => {

        return (
            <>
                {
                    valMoldes.link ?
                        <a href={`${valMoldes.link}`} target='_blanck'><Button variant='success'>Moldes</Button></a>
                        :
                        <div></div>}
            </>
        )
    }

    return (
        <React.Fragment>

            <input type='search' placeholder='Produtos e Serviços'
                id='pesquisa' list='lista' className='form-control form-control-lg'
                onChange={Valuesearch.bind()} onClick={Limparstates.bind()} ref={listFocus}
            />
            <datalist id='lista'>
                {props.produtos.map((p, index) => <option key={index}>{p.name}</option>)}

            </datalist>

            {<Buttons btn={btn}> </Buttons>}
            {<Moldes valMoldes={btn}></Moldes>}
            {<Val val={val}></Val>}
        </React.Fragment>
    )
}

export default List