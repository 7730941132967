import React, { useState, useEffect } from 'react';
import { Paper, Typography, makeStyles } from '@material-ui/core';
import Axios from '../../services/api'
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    backgroundColor: '#34aadc'
  },
}));

const MeuComponente = () => {
  const classes = useStyles();
  const [pixes, setPixes] = useState([]);

  useEffect(() => {
    fetchPixes();
  }, []);

  const fetchPixes = async () => {
    try {
      const response = await Axios.get('pix');
      const sortedPixes = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setPixes(sortedPixes);
    } catch (error) {
      console.error('Erro ao buscar Pixes:', error);
    }
  };

  return (
    <div>
      {pixes.map((pix) => (
        <Paper key={pix.id} className={classes.root}>
          <Typography variant="h6">Nome: {pix.name}</Typography>
          <Typography variant="body1">Valor: R$ {pix.value}</Typography>
          <Typography variant="body2"> {
          moment(pix.createdAt).locale('pt-br').format('DD/MM/YYYY HH:mm:ss')
        }</Typography>
        </Paper>
      ))}
    </div>
  );
};

export default MeuComponente;
