import React, { useState, useRef, useEffect } from 'react'
import { FiEdit3, FiPrinter } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { Table, Collapse, Button, Dropdown } from 'react-bootstrap'
import Axios from '../../services/api'
import Printer from '../Print/PrintPedidos'
import { useReactToPrint } from 'react-to-print'
import moment from 'moment'
import { AuthContext } from '../../context/Auth/AuthContext'
import AuthenticatedUserMenu from '../AuthenticatedUserMenu'


function List({ vendas, setVendas }) {
    
    const { user } = React.useContext(AuthContext)

    const [open, setOpen] = useState(false)
    const [indice, setIndice] = useState(null)
    const [vendedor, setVendedor] = useState(user.nome)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (user) {
            setVendedor(user.nome);
            setLoading(false);
        }
    }, [user]);


    const funcionarios = ["Alisson", "Antônio", "Bartô", "Carlos", "Lucas", 'Vendedor: Todos']

    const componentRef = useRef()
    const handlePrint = useReactToPrint({ content: () => componentRef.current, }, [componentRef.current])


    const vendasFilter = (i) => {
        return i.funcionario === vendedor
    }
    const vendasFil = vendedor === 'Vendedor: Todos' ? vendas : vendas.filter(vendasFilter)

    const vendasSort = vendasFil.sort((a, b) => {

        if (a.createdAt.substr(0, 19) > b.createdAt.substr(0, 19)) return -1;
        if (a.createdAt.substr(0, 19) < b.createdAt.substr(0, 19)) return 1;
        return 0;
    })

    function Colapse(params) {

        return (
            <div>
                {params.indice === indice && !loading ? (

                    <Collapse in={params.open}>

                        <Table style={{ color: "white" }} id={params.indice}>
                            <tbody>
                                <tr key={indice}>
                                    <td></td>

                                    <td><Link to={{/// falta fazer
                                        pathname: "/", state: { item: params.item }
                                    }}><Button variant='info'><FiEdit3 />Editar</Button></Link>
                                    </td>

                                    <td>
                                        <Button variant="danger" onClick={() => {

                                            Axios.delete(`/vendas/${params.item.id}`)
                                                .then((response) => {

                                                    Axios.get('/vendas')
                                                        .then(res => { setOpen(!open); setVendas(res.data) })
                                                })


                                        }}>Cancelar
                                        </Button>
                                    </td>
                                    <div style={{ display: 'none' }}><td>
                                        <style>{"@page {size: 120mm 170mm; margin: 5mm} "}



                                            <Printer ref={componentRef} item={params.item}></Printer></style>
                                    </td>
                                    </div>

                                    <Button variant='dark' onClick={handlePrint}>   <FiPrinter /></Button>
                                </tr>

                                <tr key={params.item.id + 4}>

                                    <td>Nomes</td>
                                    <td>Quantidade</td>
                                    <td>Preço</td>
                                </tr>

                                {params.item?.objetos?.map((i, indice) => (
                                    <tr key={indice}>

                                        <td>{i.nameAlfa} - {i.name}</td>
                                        <td>{i.quantidade}</td>
                                        <td>{`R$ ${i.precoAtual.toFixed(2)}`}</td>
                                    </tr>

                                ))}
                            </tbody>
                        </Table>


                    </Collapse>

                ) : null}

            </div>
        )
    }


    const thead = ["Data","Hora", "Itens", "Total Final", "Observações", "Cliente"]

    return (

        <Table bordered striped hover variant='dark'>

            <thead>

                <tr key={"001"}>

                {thead.map((e,index)=> <td key={index}>{e}</td>)}

                    <td>
                        <Dropdown>
                            <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                {vendedor}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {funcionarios.map((i, key) => { return <Dropdown.Item onClick={() => setVendedor(i)} key={key}>{i}</Dropdown.Item> })}

                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                    <td>Desconto</td>

                </tr>

            </thead>
            <tbody>

                {vendasSort?.map((item, indice) => {


                    const values = Object.values(item.objetos).reduce((acc, tabela) => {
                        return (acc + (tabela.precoAtual * tabela.quantidade))
                    }, 0)


                    const dateHours = moment(item.createdAt).format()
                    const date = dateHours.substr(0, 10)
                    const hora = dateHours.substr(11, 2)



                    const itensTd = [date, (hora) + item.createdAt.substr(13, 3), `${item.objetos[0].nameAlfa}  ${item.objetos[0].name}`,
                        item.desconto ? `R$ ${(values - parseFloat(item.desconto)).toFixed(2)}` : `R$ ${values.toFixed(2)}`,
                        item.obs, item.nomeCliente === "sem cliente" ? "" : item.nomeCliente,
                        item.funcionario, item.desconto > 0 ? `R$ ${item.desconto}` : "",]




                    return (
                        <>

                            <tr key={indice} style={item.nomeCliente === "sem cliente" ? { backgroundColor: "" } : { backgroundColor: "#152238" }} onClick={(e) => {
                                setIndice(indice)
                                if (e.target.localName === "td") {
                                    setOpen(!open)
                                }
                            }}>
                                {itensTd?.map((i, indice) => {


                                    return (<td key={indice}>{i}</td>)
                                })}
                            </tr>
                            <tr key={item.id + 2}>
                                <td colSpan="8">
                                    <Colapse open={open} indice={indice} item={item}></Colapse>
                                </td>
                            </tr>


                        </>)
                })}

            </tbody>
        </Table>



    )
}

export default List