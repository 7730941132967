import { Button, Modal, Spinner } from 'react-bootstrap';
import React, { useState} from 'react'
import ScrollArea from '../ScrollArea/ScrollArea'
import axios from '../../services/api'




function ModalPreco({ cliente, mudarCliente, addclienteaxios, clienteaxios }) {
  
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false)

  async function fetchData() {
    try{
      const response = await axios.get('/clientes');
        addclienteaxios(response.data)
        setLoading(false)
    }
    catch{
        console.log('erro ao Buscar Clientes')
        setLoading(false)
    }
  
  }


  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
    setLoading(true)
    fetchData()
    
  };



  return (
    <>
      <Button variant="primary" onClick={handleShow} style={{marginTop: "3px"}}>
        {cliente[0].nome}
      </Button>


      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Buscar Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!loading && <ScrollArea mudarCliente={mudarCliente} handleClose={handleClose} clienteaxios={clienteaxios}></ScrollArea>}
          {loading && <Spinner animation='border'> </Spinner>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalPreco