
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';

import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';

import adesivoImg from './images/Capa-tipo-de-adesivos-1170x480-min.png'
import { Button, Modal, Paper,
    FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';

import { takeS3 } from '../../components/TakeS3';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '40vh',
    },
    media: {
        maxHeight: 10,
        width: '100%',
        paddingTop: '56.25%', // 16:9
    },

    avatar: {
        backgroundColor: red[500],
    },
    cards: {
        margin: theme.spacing(2),
        display: 'flex',

        justifyContent: 'center',
    },
}));
const dados = [

    {
        name: 'Adesivos',
        título: 'bb',
        redondo: [
        {
            tamanho: '2x2',
            unidades: '117',
            nameS3: '2x2redondo.cdr',
        },
        {
            tamanho: '3x3',
            unidades: '48',
            nameS3: '3x3redondo.cdr',
        }, {
            tamanho: '4x4',
            unidades: '28',
            nameS3: '4x4redondo.cdr',
        }, {
            tamanho: '4,5x4,5',
            unidades: '20',
            nameS3: '4,5x4,5redondo.cdr',
        }, 
        {
            tamanho: '5x5',
            unidades: '15',
            nameS3: '5x5redondo.cdr',
        },
        {
            tamanho: '9x9',
            unidades: '6',
            nameS3: '9x9redondo.cdr',
        },
    
    
    ],
        quadrado: [],
        semForma: []

    },


]


const Moldes = () => {
    const [open, setOpen] = useState(false);
    const [cardState, setCardState] = useState([])
    const [forma, setForma] = useState('redondo')
    const classes = useStyles();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setForma(event.target.value);
    };


    

    const ModalCard = ({ card }) => (
        <div>

            <Modal open={open} onClose={handleClose} key={card.name}>
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <Paper style={{ padding: '20px', textAlign: 'center', width: '90vh', minHeight: '50vh', background: '#343a40', color: '#eee' }}>
                        <Typography variant="h6" gutterBottom>
                            {'Modal ' + cardState.name}
                        </Typography>

                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">{forma}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={forma}
                                onChange={handleChange}
                            >
                                <MenuItem value={'redondo'}>Redondo</MenuItem>
                                <MenuItem value={'quadrado'}>Quadrado</MenuItem>
                                <MenuItem value={'sem Forma'}>Sem Forma</MenuItem>
                            </Select>
                        </FormControl>

                        {card[forma] ?
                            card[forma]?.map((e, key) => {
                               
                                return (
                                    <Button variant="contained"
                                        color="default"
                                        key={key}   
                                        onClick={() => takeS3(e.nameS3)}
                                        style={{ marginLeft: '10px', borderRadius: forma === 'redondo' ? '50%' : '0%'}}
                                    >
                                          <div>
                                        <span>{e.tamanho}</span><br></br>
                                        <span>{e.unidades} und.</span>
                                        </div>
                                    </Button>



                                )
                            }) : <div></div>
                        }

                        <Button variant="contained" color="default" onClick={() => { setCardState([]); handleClose() }} style={{ marginLeft: '80%', marginTop: '40%', }}>
                            Fechar
                        </Button>
                    </Paper>
                </div>
            </Modal>

        </div>

    )

    function cards() {

        return (
            dados.map((card, key) => {

                return (
                    <div style={{ marginLeft: '10px' }} key={key}>
                        <Card className={classes.root} >
                            <CardHeader
                                title={card.name}
                            />
                            <CardMedia
                                className={classes.media}
                                image={adesivoImg}
                                title="adesivos"
                            />

                            <Button variant="contained" color="inherit" onClick={() => { setCardState(card); handleOpen() }}
                                style={{ width: '100%' }}
                            >
                                Moldes {card.name}
                            </Button>

                            {cardState.name === card.name ? <ModalCard card={card}></ModalCard> : <></>}
                        </Card>
                    </div>
                )

            })
        )

    }



    return (
        <div className={classes.cards}>
            {cards()}
        </div>
    )
}
export default Moldes;
