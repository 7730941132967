import React, { useState } from 'react';
import { Button, Typography, makeStyles,TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    modal: {
        
      },
      paper: {
       
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[2],
        padding: theme.spacing(2, 4, 3),
        outline: 'none', // Remove a borda padrão do modal
      },
      closeButton: {
        float: 'right',
      },
}));

const CustomModal = ({ onLogicComplete }) => {
  const classes = useStyles();
 
  const [height, setHeight] = useState(1); // Valor inicial para altura
  const [width, setWidth] = useState(1); // Valor inicial para largura

 
  const handleLogic = () => {

    const resultadoDaLogica = `${height} cm X ${width} cm - ${calcularQuantidadeCabe([parseFloat(height) + 0.1,parseFloat(width)+ 0.1],[19,27.7  ])} adesivos`;
    
    onLogicComplete(resultadoDaLogica); // Chama a função onLogicComplete passada pelo componente pai e passa o resultado como argumento
    
};

function calcularQuantidadeCabe(tamanhoA, tamanhoFixo) {


    // Obtém a quantidade de vezes que o tamanhoFixo cabe em cada dimensão de tamanhoA
    const vezesCabeLarguraA = parseInt((tamanhoFixo[0] / tamanhoA[0]).toFixed(2));
    const vezesCabeAlturaA = parseInt((tamanhoFixo[1] / tamanhoA[1]).toFixed(2));
    const resulA = vezesCabeLarguraA* vezesCabeAlturaA



    const vezesCabeLarguraB = parseInt((tamanhoFixo[0] / tamanhoA[1]).toFixed(2));
    const vezesCabeAlturaB = parseInt((tamanhoFixo[1] / tamanhoA[0]).toFixed(2));
    const resulB = vezesCabeLarguraB* vezesCabeAlturaB


    // Retorna a quantidade de tamanhos A que cabem no tamanhoFixo
    function resul (){
        if(resulA > resulB) return [resulA]
        if(resulA < resulB) return [resulB]
        if(resulA === resulB) return resulA
    }

    return resul()
  }

  return (
    <div>
    
      <div className={classes.paper}>
          <Typography variant="h6" id="modal-title">
            Definir Tamanho
          </Typography>

          <TextField
            label=""
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            type="number"
            inputProps={{ min: 1 }} // Mínimo valor permitido para a altura
          />
          <br />
          <TextField
            label=""
            value={width}
            onChange={(e) => setWidth(e.target.value)}
            type="number"
            inputProps={{ min: 1 }} // Mínimo valor permitido para a largura
            
          />
          <div>
            {`Na Folha Cabem ${calcularQuantidadeCabe([parseFloat(height) + 0.1,parseFloat(width)+ 0.1],[19,27.7  ])} adesivos` }
       
          </div>
          <div>
          <Button variant="contained" color="secondary" onClick={handleLogic} >
            Definir
          </Button>
          </div>
        </div>


    </div>
  );
};

export default CustomModal;
