import React, { useEffect, useState, } from 'react'
import { useHistory } from 'react-router'
import { Dropdown, Button, Modal, Form, InputGroup, Badge, Container, Accordion, Card,Col,Row} from 'react-bootstrap'
import { AuthContext } from '../../context/Auth/AuthContext'
import axios from '../../services/api'
import AddClients from '../../components/Modal/Clients/AddClients'



function ButtonAddpg({ cliente, atualizaClients }) {

    const [show, setShow] = useState(false);
    const [value, setValue] = useState(0);
    const { user } = React.useContext(AuthContext)
    const history = useHistory()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Button onClick={handleShow} style={{ marginLeft: "3px", marginTop: "3px", margin: "3px" }}> PG</Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>

                    <Modal.Title> Cliente: {cliente.nome}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>R$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control 
                        type="number" 
                        onChange={((e) => { if (e.target.value >= 0) { setValue(e.target.value) } })} 
                        value={value} 
                        placeholder="Valor" 
                        className='mb-3' >
                        </Form.Control>
                    </InputGroup>


                </Modal.Body>
                <Modal.Footer>
                    <h2>
                        <Badge variant="secondary">  Devendo R$ {cliente.debito}</Badge></h2>
                    <Button variant="secondary" onClick={(e) => {
                        
                        axios.post(('/pagamentos'), {
                            valor: value,
                            funcionario: user.nome,
                            cliente: cliente.id,
                            nomeCliente: cliente.nomeCliente,
                        }).then(() => { atualizaClients(); handleClose() })

                    }
                    }>
                        Adicionar
                    </Button>

                </Modal.Footer>



            </Modal>
        </>

    )
}

function Moviment({ cliente }) {

    const [show, setShow] = useState(false)
    const [vendas, setVendas] = useState([])


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleMap = async () => {
        try {
          const clienT = cliente?.vendas ? JSON.parse(cliente.vendas) : [];
          const { data } = await axios.get(`/movimentos`, {
            params: { id: cliente.id, vendas: clienT }
          });
          const setVenda = data.flat();
          const slic = (a) => a.slice(0, 19).replace(/[-T:]/g, "");
          const sortVenda = setVenda.sort((a, b) => slic(b.createdAt) - slic(a.createdAt));
          setVendas(sortVenda);
        } catch (error) {
          console.error(error);
          // tratar o erro aqui
        }
      };



    return (
        <>
            <Button onClick={() => {  handleMap(); handleShow(); }}>Movimentações</Button>
            <Modal size="xl" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    
                </Modal.Header>
                <Modal.Body>

                    <Container>
                        <Row>
                                <Col>Data</Col>
                                <Col>Hora</Col>
                                <Col>Tipo</Col>
                                <Col></Col>
                                <Col>Descrição</Col>
                                <Col>Total Final</Col>
                                <Col>Funcionário</Col>
                        </Row>
                        
                            {vendas.length > 0 ? vendas.map((e, index) => {

                                let {createdAt,type,objetos,desconto,funcionario, valor} = e
                                
                                let parseObjetos = null
                                if(objetos){
                                    parseObjetos = (JSON.parse(objetos))
                                }                                
                                const values = parseObjetos !== null ? Object.values(parseObjetos).reduce((acc, tabela) => {
                                    return (acc + (parseFloat((tabela.quantidade * tabela.precoAtual).toFixed(2)) ))
                                }, 0)  : parseFloat(valor)
                                
                                return (
                                    <Row key={index}>

                                    <Accordion defaultActiveKey="0" style={{width: '100%'}}>

                                        <Card style={type === undefined ? { backgroundColor: '#24ACF2' } : {}}>
                                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                                <Row>
                                                    <Col> {createdAt.substr(0, 10)}</Col>
                                                    <Col> {createdAt.substr(11, 2) + createdAt.substr(13, 3)}</Col>
                                                    <Col> {type || "Pagamento"}</Col>
                                                    <Col> {objetos ? objetos[0].nameAlfa : ""}</Col>
                                                    <Col> {objetos ? objetos[0].name : "0"}</Col>
                                                    <Col> {desconto ? `R$ ${(values - desconto).toFixed(2)}` : `R$ ${values.toFixed(2)}`}</Col>
                                                    <Col> {funcionario}</Col>
                                                </Row>
                                            </Accordion.Toggle>
    
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
    
                                                    <Container>
    
                                                        {parseObjetos && parseObjetos?.map((e, index) => {
                                                            return (
                                                               
                                                                    <Row key={index}>
                                                                        
                                                                        <Col>Quant: {e.quantidade}</Col>
                                                                        <Col>Preço: {e.precoAtual.toFixed(2)}</Col>
                                                                        <Col>{e.nameAlfa + ' '}</Col>
                                                                        <Col></Col>
                                                                        <Col>{e.name}</Col>
                                                                        <Col>Total: {`R$ ${(e.quantidade * e.precoAtual).toFixed(2)}`}</Col>
                                                                        
                                                                    </Row>
                                                                
                                                            )
                                                        })}
    
                                                        <Row>
                                                            {e.obs}
                                                        </Row>
                                                        <Row>
                                                            ID {e.type ? 'Venda' : 'Pagamento'}: {e.id}
                                                        </Row>
                                                    </Container>

                                                </Card.Body>
                                            </Accordion.Collapse>
    
                                        </Card>
                                    </Accordion>
                                </Row>
                                ) 


                            }) : 
                            <div></div>
                            
                            }

                            






                        

                    </Container>




                </Modal.Body>
                <Modal.Footer>
                    <h2>
                        <Badge variant="secondary">  Devendo R$ {cliente.debito}</Badge></h2>
                    <Button variant="secondary">
                        Adicionar
                    </Button>

                </Modal.Footer>



            </Modal>
        </>
    )
}



function Client() {
    const { user } = React.useContext(AuthContext)
    const [clients, setClients] = useState([])
    

    const atualizaClients = async () => await axios.get('/clientes').then(({ data }) => {
           
        setClients(data)
    })


    useEffect(() => {

        async function fetchData() {

            const response = await axios.get('/clientes').then(({ data }) => {
            
                setClients(data)
            })

        }
        fetchData()

    }, [])




    function buscaCliente() { axios.get('/clientes').then(({ data }) => setClients(data)) }



    return (
        <React.Fragment>

            <Container style={{ color: '#eee', marginTop: '10px'}} fluid >
            
                <Row hover>
                    
                        <Col>{
                            <AddClients buscaCliente={buscaCliente}></AddClients>}

                        </Col>
                        <Col >Nome</Col>
                        <Col>Débito</Col>
                        <Col>Número</Col>
                    
                </Row>
       

                    {clients.map((c, key) => {

                       

                        return (
                            <div className="hover-container" key={key}>
                                <Dropdown.Divider style={{borderColor: '#000'}}/>
                            <Row  >

                                {user === "Carlos" ? 
                                <Col>
                                    <Button variant='danger' onClick={() => { axios.delete(`/clientes/${c.id}`).then(buscaCliente()) }}>X</Button>
                                    <ButtonAddpg cliente={c} atualizaClients={atualizaClients}></ButtonAddpg>
                                    <Moviment cliente={c}></Moviment>
                                </Col>
                                    :
                                <Col><ButtonAddpg cliente={c} atualizaClients={atualizaClients}></ButtonAddpg>
                                    <Moviment cliente={c}></Moviment>
                                </Col>}


                                <Col>{c.nome}</Col>
                                <Col>R$ {c.debito ? c.debito : 0}</Col>
                                <Col>{c.numero}</Col>
                               
                            </Row>
                             
                             </div>
                            )
                    }
                    )}




                
            </Container>
    
            </React.Fragment>
    )
}

export default Client