import AWS from 'aws-sdk'

export const s3 = new AWS.S3({
    endpoint: process.env.REACT_APP_ENDPOINT,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
    s3BucketEndpoint: true,
    signatureVersion: 'v4',
});


 


export const getSignedUrl =(key)=>{
            const time = 43200;
            const signedUrlParams = {
                Bucket: "producao",
                Key: key,
                Expires: time
            };

            // Obtendo a URL assinada
            const signedUrl = s3.getSignedUrl('getObject', signedUrlParams);
            return signedUrl;
}

 