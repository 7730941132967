import React, { useEffect, useState } from 'react';
import {
    Grid,
    FormControl,
    FormControlLabel,
    InputLabel,
    Select, Checkbox,
    FormGroup,
    Button,
    Input,
    TextField,
    Typography,
    Paper,
    MenuItem,
    Card,
    CardContent,
    LinearProgress
} from '@material-ui/core';

import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { getFileFormat } from '../libFunctions';

import { AuthContext } from '../../context/Auth/AuthContext'


import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/core/styles';
import Axios from '../../services/api';
import { s3, getSignedUrl } from '../MonitorS3';








const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
    },
    button: {
        marginBottom: theme.spacing(2),
    },
    paper: {
        marginTop: theme.spacing(3),
        minWidth: '60%',
        backgroundColor: '#EAEAEA',
        borderRadius: '10px',
        background: '#eee'
    },
    select: {
        margin: theme.spacing(2),
        minWidth: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    formContainer: {

        padding: theme.spacing(3),
        borderRadius: 8,
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        maxWidth: 400,
    },

    fileInput: {
        display: 'none',
        marginLeft: theme.spacing(2),
    },
    fileInputLabel: {

        padding: theme.spacing(1, 2),
        borderRadius: 8,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
        progress: {
            position: 'absolute',
            zIndex: 1,
            top: 0,
            width: '100%',
            height: '800px',
        },

    },
    card: {
        maxWidth: 150,
        maxHeight: 100,
        margin: 'auto',
        marginTop: theme.spacing(1),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        padding: theme.spacing(1),
    },
    input: {
        display: 'none',
    },




}));

export const UploadS3 = ({ pegarDadosFilho, upDados, id, uploads }) => {
    const classes = useStyles();
    const [files, setFiles] = useState(uploads || [])
    const { user } = React.useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    console.log(files)

    const handleArteChange = async (event) => {
        // Acessamos o arquivo selecionado pelo usuário
        let selectedFile = event.target.files[0];
        console.log(selectedFile, 'sss')



        if (!selectedFile) {
            alert('Selecione um arquivo para fazer upload');
            return;
        }

        setLoading(true)


        const response = await Axios.get('/uploadS3',
            {
                params: {
                    name: selectedFile.name,

                }
            });

        if (!response) return;

        const uploadParams = {
            Body: selectedFile,
            Bucket: "producao",
            Key: response.data[0],
            ContentDisposition: 'inline',
            ContentType: 'application/octet-stream',
        };


        selectedFile.dateNow = response.data[1]
        selectedFile.progress = 0
        selectedFile.index = files.length
        selectedFile.tamanho = selectedFile.size
        selectedFile.nameS3 = response.data[0]
        selectedFile.funcionario = user.nome

        event.target.value = null;

        setFiles([...files, selectedFile])


        // Iniciando o upload
        const uploadRequest = s3.putObject(uploadParams);
        const fileSize = selectedFile.size;




        uploadRequest.on('httpUploadProgress', function (progress) {
            //    console.log(`Progresso do Upload: ${progress.loaded} de ${progress.total} bytes`);

            const percentage = Math.round((progress.loaded / fileSize) * 100);


            setFiles(prevFiles => {
                // Criando uma cópia do array de arquivos
                const updatedFiles = [...prevFiles];

                // Atribuindo a porcentagem de progresso ao arquivo selecionado
                updatedFiles[selectedFile.index].progress = percentage;

                // Retornando o novo array de arquivos atualizado
                return updatedFiles;
            });


        });


        // Enviando a requisição de upload
        uploadRequest.promise().then(async e => {


            // Obtendo a URL assinada
            const signedUrl = getSignedUrl(response.data[0])
            selectedFile.signedUrl = signedUrl
            selectedFile.pedidosId = id


            console.log(selectedFile, 'response.data[0]')

            await Axios.post('/uploadS3',
                {
                    file: selectedFile
                }).then((data) => {


                    setFiles(prevFiles => {

                        // Criando uma cópia do array de arquivos
                        const updatedFiles = [...prevFiles];

                        // Atribuindo a porcentagem de progresso ao arquivo selecionado
                        updatedFiles[selectedFile.index].signedUrl = signedUrl;

                        updatedFiles[selectedFile.index].id = data.data;

                        // Retornando o novo array de arquivos atualizado
                        return updatedFiles;
                    });

                })

            setLoading(false)
        }).catch(err => {
            console.error('Erro ao fazer upload:', err);
        });





    };

    const checkboxStyle = {
        display: 'inline',
        margin: '30px',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };
    useEffect(() => {

        if (!loading && files.length > 0) {

            pegarDadosFilho(files)

        }


    }, [files, loading])

    useEffect(() => {
        if (loading) { pegarDadosFilho('pending') }


    }, [loading])


    function kbToMb(kb) {
        return kb / (1024 * 1024);
    }






    return (
        <React.Fragment>
            <input
                type="file"
                id="upload-button"
                style={{ display: 'none' }}
                onChange={handleArteChange}
            />
            <label htmlFor="upload-button">
                <Button variant="contained" color='secondary' component="span" style={{ marginLeft: '10px' }}>
                    Upload
                </Button>
            </label>

            {files.length > 0 && (
                <>

                    <div >
                        <ExpansionPanel style={{background: '#9899B1'}}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6">{files.length} Arquivos</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={2}>
                                    {files.map((e, index) => {

                                        return (

                                            <>


                                                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                                    <Card >
                                                        <CardContent className={classes.card}>

                                                            {
                                                                e.fileName ?
                                                                    <>
                                                                        <div><h7>{e.fileName.slice(8)}</h7></div>
                                                                        <Button
                                                                            variant='contained'
                                                                            color='primary'
                                                                            style={{ width: '100%', height: '200%' }}
                                                                            onClick={() => {
                                                                                try {

                                                                                    const signedUrl = getSignedUrl(e.fileName);
                                                                                    window.open(signedUrl, '_blank'); // Abre a URL assinada em uma nova aba
                                                                                } catch (error) {
                                                                                    console.error('Erro ao obter URL assinada:', error);
                                                                                }
                                                                            }}
                                                                        >

                                                                            <GetAppIcon />
                                                                        </Button>
                                                                        <p> {getFileFormat(e.fileName)} - {kbToMb(e.size).toFixed(2)} MB</p>
                                                                    </>

                                                                    :
                                                                    e.signedUrl ?
                                                                        <>
                                                                            <div><h7>{e.name}</h7></div>
                                                                            <Button
                                                                                variant='contained'
                                                                                color='primary'
                                                                                style={{ width: '100%', height: '200%' }}
                                                                                onClick={() => {
                                                                                    try {

                                                                                        const signedUrl = getSignedUrl(e.nameS3);
                                                                                        window.open(signedUrl, '_blank'); // Abre a URL assinada em uma nova aba
                                                                                    } catch (error) {
                                                                                        console.error('Erro ao obter URL assinada:', error);
                                                                                    }
                                                                                }}
                                                                            >

                                                                                <GetAppIcon />
                                                                            </Button>

                                                                        </>
                                                                        :
                                                                        <h7>{e.name}</h7>


                                                            }





                                                            {e.progress && e.progress !== 100 && (
                                                                <>
                                                                    <LinearProgress variant="determinate" value={e.progress} />
                                                                    Upload: {e.progress}%
                                                                </>
                                                            )}
                                                            <br />


                                                        </CardContent>
                                                        {/* <TextField
                                        autoFocus
                                        className={classes.commentInput}
                                        label="Digite seu comentário"
                                        variant="outlined"
                                        multiline

                                    /> */}
                                                    </Card>

                                                </Grid>


                                            </>

                                        )

                                    })}
                                </Grid>


                            </ExpansionPanelDetails>
                        </ExpansionPanel>


                    </div>




                </>



            )




            }




        </React.Fragment>
    )
}