
const produtos = [
    {
        name: 'IMPRESSÃO A4', subproduto: [
            {
                name: 'Preto *F',
                preco: [{ quantidade: [0, 2.00] }, { quantidade: [2, 1.00] }, { quantidade: [3, 0.665] }, { quantidade: [4, 0.50] },{ quantidade: [10, 0.40] }, { quantidade: [30, 0.30] }, { quantidade: [50, 0.20] }], 
                precoAtual: null,
                style: "black",
                encf: 10,
                modal: 'Papel Comum'
            },

            {
                name: 'Colorido *F',
                preco: [{ quantidade: [0, 2.00] }, { quantidade: [2, 1.00] }, { quantidade: [6, 0.80] }, { quantidade: [10, 0.50] }, { quantidade: [50, 0.30] },], precoAtual: null
                ,
                style: "red",
                encf: 10,
                modal: 'Papel Comum'
            },

            {
                name: 'Preto *FV',
                preco: [{ quantidade: [0, 2.00] }, { quantidade: [2, 1.00] }, { quantidade: [3, 0.665] }, { quantidade: [4, 0.50] }, { quantidade: [10, 0.40] }, { quantidade: [30, 0.30] }, { quantidade: [50, 0.18] }, { quantidade: [100, 0.13] }], precoAtual: null
                ,
                style: "black",
                encfv: 10,
                modal: 'Papel Comum'
            },

            {
                name: 'Colorido *FV',
                preco: [{ quantidade: [0, 2.00] }, { quantidade: [2, 1.00] }, { quantidade: [3, 0.665] }, { quantidade: [4, 0.50] }, { quantidade: [20, 0.40] }, { quantidade: [40, 0.30] }, { quantidade: [50, 0.20] }], precoAtual: null
                ,
                style: "red",
                encfv: 10,
                modal: 'Papel Comum'
            },

            {
                name: 'COUCHÊ',
                preco: [{ quantidade: [0, 3.00] }, { quantidade: [5, 2.50] }, { quantidade: [10, 2.00] }, { quantidade: [50, 1.30] }], precoAtual: null,
                modal: 'Papel Couche'
            },

            {
                name: 'COUCHÊ FV',
                preco: [{ quantidade: [0, 4.00] }, { quantidade: [5, 3.50] }, { quantidade: [10, 3.00] }, { quantidade: [50, 2.00] }], precoAtual: null,
                modal: 'Papel Couche'
            },
            {
                name: 'FOTO',
                preco: [{ quantidade: [0, 3.00] }, { quantidade: [5, 2.50] }, { quantidade: [10, 2.00] }, { quantidade: [50, 1.30] },], precoAtual: null,
                modal: 'Papel Foto'
            },
             {
                name: 'ADESIVO',
                preco: [{ quantidade: [0, 3.00] }, { quantidade: [10, 2.50] }, { quantidade: [50, 1.50] },], precoAtual: null,
                modal: 'Papel Adesivo'
            },
             {
                name: 'ADESIVO Recortado',
                preco: [{ quantidade: [0, 5.00] }, { quantidade: [5, 4.00] }, { quantidade: [10, 3.50] },{ quantidade: [50, 2.50] },], precoAtual: null,
                modal: 'Papel Adesivo'
            },
            {
                name: 'ADESIVO Vinil',
                preco: [{ quantidade: [0, 7.00] },], precoAtual: null,
                modal: 'Papel Adesivo'
            },
        ], modal: ['Papel Comum', 'Papel Couche', 'Papel Foto','Papel Adesivo']
    },
    {
        name: 'CÓPIAS', subproduto: [
            {
                name: 'PRETO F',
                preco: [{ quantidade: [0, 0.50] }, { quantidade: [15, 0.40] }, { quantidade: [20, 0.30] }, { quantidade: [50, 0.25] }], precoAtual: null
                ,
                style: "black"
            },
            {
                name: 'COLORIDO F',
                preco: [{ quantidade: [0, 0.50] }, { quantidade: [30, 0.40] }, { quantidade: [50, 0.30] },], precoAtual: null
                ,
                style: "red"
            },
            {
                name: 'PRETO FV',
                preco: [{ quantidade: [0, 0.50] }, { quantidade: [30, 0.40] }, { quantidade: [50, 0.30] },], precoAtual: null,
                style: "black"
            },
            {
                name: 'COLORIDO FV',
                preco: [{ quantidade: [0, 0.50] }, { quantidade: [30, 0.40] }, { quantidade: [50, 0.30] },], precoAtual: null
                ,
                style: "red"
            },



          
        ]
    },

    {
        name: 'IMPRESSÃO A0,A1,A2,A3', subproduto: [
            {
                name: 'A0',
                preco: [{ quantidade: [0, 16.00] }], precoAtual: null
            },
            {
                name: 'A0 COMPLETO',
                preco: [{ quantidade: [0, 25.00] }], precoAtual: null
            },
            {
                name: 'A1',
                preco: [{ quantidade: [0, 8.00] }], precoAtual: null
            },
            {
                name: 'A1 {PLANTA}',
                preco: [{ quantidade: [0, 7.00] }], precoAtual: null
            },
            {
                name: 'A2',
                preco: [{ quantidade: [0, 5.00] }], precoAtual: null
            },
            {
                name: 'A3 Comum',
                preco: [{ quantidade: [0, 2.50] }, { quantidade: [10, 2.00] },{ quantidade: [50, 1.50] }], precoAtual: null
            },
            {
                name: 'A3 Couche',
                preco: [{ quantidade: [0, 5.00] }, { quantidade: [10, 4.00] }, { quantidade: [30, 3.00] }], precoAtual: null
            },
            {
                name: 'A3 Adesivo',
                preco: [{ quantidade: [0, 6.00] },{ quantidade: [10, 5.00] }], precoAtual: null
            },
        ]
    },

    {
        name: 'BOLETO', subproduto: [
            {
                name: 'Fatura',
                preco: [{ quantidade: [0, 2.00] }], precoAtual: null
            },
            {
                name: 'Detran',
                preco: [{ quantidade: [0, 5.00] }], precoAtual: null
            },
            {
                name: 'Detran com multa',
                preco: [{ quantidade: [0, 6.00] }], precoAtual: null
            },
        ]
    },
    {
        name: 'BANNER', subproduto: [
            {
                name: '30X40 CM',
                preco: [{ quantidade: [0, 20.00] }], precoAtual: null
            },
            {
                name: '60X40 CM',
                preco: [{ quantidade: [0, 35.00] }], precoAtual: null
            },
            {
                name: '90X60 CM',
                preco: [{ quantidade: [0, 45.00] }], precoAtual: null
            },
             {
                name: '80X120 CM',
                preco: [{ quantidade: [0, 70.00] }], precoAtual: null
            },
             {
                name: '100X150 CM',
                preco: [{ quantidade: [0, 6.00] }], precoAtual: null
            },
            
            
        ]
    },
    
    
    
    {
        name: 'TÍTULO DE ELEITOR', subproduto: [
            {
                name: 'IMPRESSO E PLASTIFICADO',
                preco: [{ quantidade: [0, 10.00] }], precoAtual: null
            },
            {
                name: 'IMPRESSO',
                preco: [{ quantidade: [0, 8.00] }], precoAtual: null
            },
           
        ]
    },
    {
        name: 'LIVRO CALIGRAFIA', subproduto: [
            {
                name: 'IMPRESSO E ENCADERNADO',
                preco: [{ quantidade: [0, 10.00] }], precoAtual: null
            },
         
           
        ]
    },

    {
        name: 'PLASTIFICAÇÃO', subproduto: [
            {
                name: 'RG',
                preco: [{ quantidade: [0, 2.50] }], precoAtual: null
            },
            {
                name: 'MÉDIO',
                preco: [{ quantidade: [0, 4.00] }], precoAtual: null
            },
            {
                name: 'A4',
                preco: [{ quantidade: [0, 5.00] }, { quantidade: [10, 2.50] }], precoAtual: null
            },
            {
                name: 'A3',
                preco: [{ quantidade: [0, 8.00] }, { quantidade: [10, 5.00] }], precoAtual: null
            },
        
        ]
    },
    {
        name: 'ETIQUETA ESCOLAR', subproduto: [
            {
                name: 'KIT 1 SIMPLES',
                preco: [{ quantidade: [0, 20.00] }], precoAtual: null,
                modal: 'KIT 1'
            },
             {
                name: 'KIT 1 VINIL',
                preco: [{ quantidade: [0, 30.00] }], precoAtual: null,
                modal: 'KIT 1'
            },
            {
                name: 'KIT 1 SIMPLES COM 3 FOLHAS',
                preco: [{ quantidade: [0, 25.00] }], precoAtual: null,
                modal: 'KIT 1'
            },
           
        
        ], modal: ['KIT 1']
    },
    {
        name: 'ENCADERNAÇÃO', subproduto: [
            {
                name: 'até 50 FL.',
                preco: [{ quantidade: [0, 3.00] }], precoAtual: null,
                toast: 50
            },
            {
                name: 'até 100 FL.',
                preco: [{ quantidade: [0, 4.00] }], precoAtual: null,
                toast: 100
            },
            {
                name: 'até 200 FL.',
                preco: [{ quantidade: [0, 5.00] }], precoAtual: null,
                toast: 200
            },
            {
                name: 'até 300 FL.',
                preco: [{ quantidade: [0, 6.00] }], precoAtual: null,
                toast: 300
            },
            {
                name: 'até 400 FL.',
                preco: [{ quantidade: [0, 7.00] }], precoAtual: null,
                toast: 400
            },

        ]
    },
    {
        name: 'PLACA PIX', subproduto: [
            {
                name: 'Em Adesivo',
                preco: [{ quantidade: [0, 12.00] }], precoAtual: null,
                
            },
            {
                name: 'Plastificada',
                preco: [{ quantidade: [0, 15.00] }], precoAtual: null,
                
            },
          

        ],
        link: 'https://drive.google.com/drive/folders/1bQO2NS8mBZhoqYdxPhhImNMKyulzgISY?usp=sharing'
    },

    {
        name: 'RIFA', subproduto: [
            {
                name: '100',
                preco: [{ quantidade: [0, 12.00] }], precoAtual: null
            },
            {
                name: '100 + CORTE e GRAMPO',
                preco: [{ quantidade: [0, 15.00] }], precoAtual: null
            },
            {
                name: '200',
                preco: [{ quantidade: [0, 18.00] }], precoAtual: null
            },
            {
                name: '200 + CORTE e GRAMPO',
                preco: [{ quantidade: [0, 22.00] }], precoAtual: null
            },
            {
                name: '300',
                preco: [{ quantidade: [0, 24.00] }], precoAtual: null
            },
            {
                name: '300 + CORTE e GRAMPO',
                preco: [{ quantidade: [0, 30.00] }], precoAtual: null
            },
            {
                name: '400',
                preco: [{ quantidade: [0, 29.00] }], precoAtual: null
            },
            {
                name: '400 + CORTE e GRAMPO',
                preco: [{ quantidade: [0, 34.00] }], precoAtual: null
            },
            {
                name: '500',
                preco: [{ quantidade: [0, 34.00] }], precoAtual: null
            },
            {
                name: '500 + CORTE e GRAMPO',
                preco: [{ quantidade: [0, 39.00] }], precoAtual: null
            },

        ],
        link: 'https://drive.google.com/drive/folders/1DUm-7T4eq508Kl6ufb2cRp7JDW3psstq?usp=sharing'
    },
    {
        name: 'DIGITALIZAR', subproduto: [
            {
                name: 'SCAN',
                preco: [{ quantidade: [0, 2.00] }, { quantidade: [2, 1.00] }, { quantidade: [8, 0.50] }, { quantidade: [20, 0.40] },], precoAtual: null
            },

        ]
    },
    {
        name: 'CURRICULO', subproduto: [
            {
                name: 'SIMPLES',
                preco: [{ quantidade: [0, 10.00] },], precoAtual: null
            },
            {
                name: 'DETALHADO',
                preco: [{ quantidade: [0, 14.00] },], precoAtual: null
            },
        ],
        link: "https://drive.google.com/drive/folders/1hapW258xhTHnas0YtaTcfOEbwhEYcdHB?usp=sharing"
    },
    {
        name: 'CPF', subproduto: [
            {
                name: '2ª VIA E PLASTIFICADO',
                preco: [{ quantidade: [0, 8.00] },], precoAtual: null
            },
        ]
    },
     
    {
        name: 'BLOQUINHO ANOTACOES', subproduto: [
            {
                name: '10X7',
                preco: [{ quantidade: [0, 2.00] },{ quantidade: [40, 1.80] },], precoAtual: null
            },
        ]
    },
    {
        name: 'ARTE', subproduto: [
            {
                name: 'Arte R$ 2,00',
                preco: [{ quantidade: [0, 2.00] }], precoAtual: null
            },
            {
                name: 'Arte R$ 3,00',
                preco: [{ quantidade: [0, 3.00] }], precoAtual: null
            },
            {
                name: 'Arte R$ 3,00',
                preco: [{ quantidade: [0, 4.00] }], precoAtual: null
            },
            {
                name: 'Arte R$ 4,00',
                preco: [{ quantidade: [0, 4.00] }], precoAtual: null
            },
            {
                name: 'Arte R$ 5,00',
                preco: [{ quantidade: [0, 5.00] }], precoAtual: null
            },
            {
                name: 'Arte R$ 5,00',
                preco: [{ quantidade: [0, 6.00] }], precoAtual: null
            },
            {
                name: 'Arte R$ 5,00',
                preco: [{ quantidade: [0, 7.00] }], precoAtual: null
            },
            {
                name: 'Arte R$ 5,00',
                preco: [{ quantidade: [0, 8.00] }], precoAtual: null
            },
            {
                name: 'Arte R$ 5,00',
                preco: [{ quantidade: [0, 9.00] }], precoAtual: null
            },
            {
                name: 'Arte R$ 10,00',
                preco: [{ quantidade: [0, 10.00] }], precoAtual: null
            },
        ]
    },
    {
        name: 'CARTÃO DE VISITA', subproduto: [
            {
                name: '100 frente',
                preco: [{ quantidade: [0, 20.00] }], precoAtual: null
            },
            {
                name: '100 frente e verso',
                preco: [{ quantidade: [0, 30.00] }], precoAtual: null
            },
            {
                name: '200 frente',
                preco: [{ quantidade: [0, 30.00] }], precoAtual: null
            },
            {
                name: '200 frente e verso',
                preco: [{ quantidade: [0, 40.00] }], precoAtual: null
            },
            {
                name: '500 frente',
                preco: [{ quantidade: [0, 60.00] }], precoAtual: null
            },
            {
                name: '500 frente e verso',
                preco: [{ quantidade: [0, 75.00] }], precoAtual: null
            },
            {
                name: '1000 frente',
                preco: [{ quantidade: [0, 80.00] }], precoAtual: null
            },
            {
                name: '1000 frente e verso',
                preco: [{ quantidade: [0, 100.00] }], precoAtual: null
            },
        ]
    },
    {
        name: 'TOPO DE BOLO', subproduto: [
            {
                name: 'TOPO',
                preco: [{ quantidade: [0, 8.00] },], precoAtual: null
            },
            {
                name: 'TOPO - Várias Pesquisas',
                preco: [{ quantidade: [0, 13.00] },], precoAtual: null
            },

        ]
    },
    {
        name: 'CONSULTA CPF', subproduto: [
            {
                name: 'SERASA',
                preco: [{ quantidade: [0, 12.00] },], precoAtual: null
            },
            

        ]
    },


    {
        name: 'CHAVEIRO 3X4', subproduto: [
            {
                name: 'Chaveiro 3x4 Acrilico',
                preco: [{ quantidade: [0, 2.50] }, { quantidade: [30, 1.80] }], precoAtual: null
            },

        ]
    },
    {
        name: 'PORTA DOCUMENTOS', subproduto: [
            {
                name: 'RG, CNH',
                preco: [{ quantidade: [0, 1.00] },], precoAtual: null
            },
            {
                name: 'PASSAPORTE',
                preco: [{ quantidade: [0, 2.00] },], precoAtual: null
            },


        ]
    },
    {
        name: 'PORTA RETRATO', subproduto: [
            {
                name: '10 X 15 CM',
                preco: [{ quantidade: [0, 8.00] },], precoAtual: null
            },




        ]
    },
    {
        name: 'CANECA', subproduto: [
            {
                name: 'SIMPLES',
                preco: [{ quantidade: [0, 30.00] },], precoAtual: null
            },




        ]
    },
    {
        name: 'PACOTE PAPEL', subproduto: [
            {
                name: 'PAPEL REPORT 500fls',
                preco: [{ quantidade: [0, 30.00] },], precoAtual: null
            },
            {
                name: 'PAPEL SENINHA 100 FLS BRANCO',
                preco: [{ quantidade: [0, 7.00] },], precoAtual: null
            },
            {
                name: 'PAPEL SENINHA 100 FLS COLORIDO',
                preco: [{ quantidade: [0, 8.00] },], precoAtual: null
            },
            {
                name: 'FOTO 20 FLS 180g',
                preco: [{ quantidade: [0, 12.00] },], precoAtual: null
            },
            {
                name: 'FOTO 50 FLS 180g',
                preco: [{ quantidade: [0, 30.00] },], precoAtual: null
            },
            {
                name: 'FOTO ADESIVO 20 FLS',
                preco: [{ quantidade: [0, 18.00] },], precoAtual: null
            },



        ]
    },
    {
        name: 'ENVELOPE', subproduto: [
            {
                name: 'A4',
                preco: [{ quantidade: [0, 0.50] },], precoAtual: null
            },
            {
                name: '10X15',
                preco: [{ quantidade: [0, 0.30] },], precoAtual: null
            },
            {
                name: 'TRANSPARENTE',
                preco: [{ quantidade: [0, 0.50] },], precoAtual: null
            },


        ]
    },
    {
        name: 'MOUSE USB', subproduto: [
            {
                name: 'MULTILASER',
                preco: [{ quantidade: [0, 17.00] },], precoAtual: null
            },



        ]
    },

    {
        name: 'TECLADO USB', subproduto: [
            {
                name: 'MULTILASER',
                preco: [{ quantidade: [0, 45.00] },], precoAtual: null
            },



        ]
    },
    {
        name: 'FONE DE OUVIDO', subproduto: [
            {
                name: 'MULTILASER',
                preco: [{ quantidade: [0, 17.00] },], precoAtual: null
            },



        ]
    },
    {
        name: 'CAIXA DE GRAMPOS', subproduto: [
            {
                name: 'GRAMPOS',
                preco: [{ quantidade: [0, 5.00] },], precoAtual: null
            },



        ]
    },
    {
        name: 'LIVRO DE PINTAR OU COLORIR', subproduto: [
            {
                name: 'A5',
                preco: [{ quantidade: [0, 4.00] },], precoAtual: null
            },



        ]
    },
    {
        name: 'ENCAPAR CADERNO', subproduto: [
            {
                name: 'COMPLETO COM LAMINAÇÃO',
                preco: [{ quantidade: [0, 15.00] },], precoAtual: null
            },



        ]
    },
    {
        name: 'LÁPIS DE COLORIR', subproduto: [
            {
                name: 'GIZ DE CERA P',
                preco: [{ quantidade: [0, 4.00] },], precoAtual: null
            },
            {
                name: 'MULTI COLOR P',
                preco: [{ quantidade: [0, 5.00] },], precoAtual: null
            },
            {
                name: 'MULTI COLOR M',
                preco: [{ quantidade: [0, 7.00] },], precoAtual: null
            },



        ]
    },
    {
        name: 'FOLHA AVULSA', subproduto: [
            {
                name: 'COMUM',
                preco: [{ quantidade: [0, 0.10] },], precoAtual: null
            },
            {
                name: 'FOTO 180g',
                preco: [{ quantidade: [0, 1.00] },], precoAtual: null
            },
            {
                name: 'FOTO ADESIVA',
                preco: [{ quantidade: [0, 1.00] },], precoAtual: null
            },



        ]
    },

    {
        name: 'SERVIÇOS', subproduto: [
            {
                name: '2,00',
                preco: [{ quantidade: [0, 2.00] },], precoAtual: null
            },
            {
                name: '3,00',
                preco: [{ quantidade: [0, 3.00] },], precoAtual: null
            },
            {
                name: '4,00',
                preco: [{ quantidade: [0, 4.00] },], precoAtual: null
            },
            {
                name: '5,00',
                preco: [{ quantidade: [0, 5.00] },], precoAtual: null
            },
            {
                name: '6,00',
                preco: [{ quantidade: [0, 6.00] },], precoAtual: null
            },
            {
                name: '7,00',
                preco: [{ quantidade: [0, 7.00] },], precoAtual: null
            },
            {
                name: '8,00',
                preco: [{ quantidade: [0, 8.00] },], precoAtual: null
            },
            {
                name: '9,00',
                preco: [{ quantidade: [0, 9.00] },], precoAtual: null
            },
            {
                name: '10,00',
                preco: [{ quantidade: [0, 10.00] },], precoAtual: null
            },
            {
                name: '15,00',
                preco: [{ quantidade: [0, 15.00] },], precoAtual: null
            },

        ]
    },


]


export default produtos