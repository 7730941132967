import { Button, Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react'
import Axios from '../../services/api'
import { AuthContext } from '../../context/Auth/AuthContext'
import { valorTotal } from '../Total Produtos/TotallProdutos'
import toastError from '../../errors/toastError';
import CircularProgress from '@material-ui/core/CircularProgress';

function ModalSave(params) {

  const [desconto, Setdesconto] = useState(0)
  const [showSave, setShowSave] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (params.desconto && params.desconto > desconto) return Setdesconto(params.desconto)
  }, [params.desconto])


  const handleClose = () => setShowSave(false)
  const handleShow = () => setShowSave(true)

  useEffect(() => {
    document.onkeyup = (e) => {

      if (e.key === 'F2') {
        if (showSave === false) { handleShow() }
      }

    }
  })
  const { user } = React.useContext(AuthContext)



  async function save() {
    setLoading(true)
    await Axios.post('/vendas', {

      obs: params.obs,
      objetos: params.tabela,
      funcionario: user.nome,
      desconto: desconto,
      type: "venda",
      cliente: params.cliente[0]?.nome === "Buscar Cliente:" ? "sem cliente" : params.cliente[0].id,
      nomeCliente: params.cliente[0]?.nome === "Buscar Cliente:" ? "sem cliente" : params.cliente[0].nome
    })
      .then((response) => {

        params.history.push("/")
      }).catch((err)=>{
        toastError(err)
        setLoading(false)
      })

  }
  async function savePedido() {
    setLoading(true)
    await setTimeout(() => {
      Axios.post('/pedidos', {
        obs: params.obs,
        objetos: params.tabela,
        funcionario: user.nome,
        desconto: desconto,
        type: "pedido",
        cliente: params.cliente[0]?.nome === "Buscar Cliente:" ? "sem cliente" : params.cliente[0].id,
        //cliente = id venda
        nomeCliente: params.cliente[0]?.nome === "Buscar Cliente:" ? "sem cliente" : params.cliente[0].nome
      })
        .then((response) => {
         
          params.history.push({
            pathname: '/pedidos',
            state: response.data // Aqui você pode passar os parâmetros que deseja
          })
  
        })
        .catch((err)=>{
          toastError(err)
          setLoading(false)
        })
    }, 2000);

  

  }
  function editVenda() {



    Axios.put(`/vendas/${params.id}`, {
      obs: params.obs,
      objetos: params.tabela,
      funcionario: user.nome,
      desconto: desconto,
      type: "venda",
      cliente: params.cliente[0]?.nome === "Buscar Cliente:" ? "sem cliente" : params.cliente[0].id,
      nomeCliente: params.cliente[0]?.nome === "Buscar Cliente:" ? "sem cliente" : params.cliente[0].nome
    }).then((response) => {


      params.history.push("/")
    })


  }
  function editPedido() {
    Axios.put(`/pedidos/${params.id}`, {
      obs: params.obs,
      objetos: params.tabela,
      funcionario: user.nome,
      desconto: desconto,
      type: "pedido",
      cliente: params.cliente[0]?.nome === "Buscar Cliente:" ? "sem cliente" : params.cliente[0].id,
      nomeCliente: params.cliente[0]?.nome === "Buscar Cliente:" ? "sem cliente" : params.cliente[0].nome
    }).then(() => {
      params.history.push("/pedidos")
    })


  }

  useEffect(() => {
    document.onkeydown = (e) => {
      //console.log(e)

    }
  })


  //console.log(params.tabela)

  return (
    <div style={{ display: "flex", justifyContent: "center" }} 
      onKeyUp={(e) => { 
        if (e.key === "Enter" && !params.type && params.tabela[0]?.name && !loading) { save() } 
        if (e.key === "F9" && !params.type && params.tabela[0]?.name && !loading) { savePedido() } 
        }}>

      <Button variant="primary" onClick={handleShow} style={{ position: "relative", width: "80%", height: "80%", }}>
        {params.type === "pedido" ? "Pedido" : "Venda ou Pedido"}
      </Button>

      <Modal show={showSave} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>Total a Pagar</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5>Soma: {valorTotal(params.tabela)}</h5>
          <br></br>
          <br></br>
          <h6> <strong>Desconto:</strong></h6>
          <input type="number" value={desconto} onChange={(i) => {
            const total = valorTotal(params.tabela)
            if (parseInt(i.target.value) >= 0 && i.target.value < parseInt(total)) { Setdesconto(i.target.value) }
          }} />
          <br></br>
          <br></br>
          <h2> Total R$ {(valorTotal(params.tabela) - desconto).toFixed(2)} </h2>
        </Modal.Body>
        <Modal.Footer>

          {!params.type && params.tabela[0]?.name && !loading? <div>
            <Button onClick={save} variant="success">Salvar Venda || Enter</Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button variant="info" onClick={savePedido}> Salvar Pedido  || F9</Button> </div> : <div></div>}
          {params.type !== "pedido" && params.type === "venda" && params.tabela[0]?.name ? <Button onClick={editVenda} variant="success">Editar Venda</Button> : <div></div>}
          {params.type !== "venda" && params.type && params.tabela[0]?.name ? <Button variant="info" onClick={editPedido}> Salvar Pedido</Button> : <div></div>}
            {loading && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
              <CircularProgress />
            </div>
            )}
        </Modal.Footer>
      </Modal>
    </div>
  );


}



export default ModalSave