import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import produtos from '../Produtos';




function PrintOptions({ onSelect,quantity }) {



  const valorProduto = (impressao)=>{

    const subproduto = produtos.find(produto => produto.subproduto.some(sub => sub.name === impressao));
   
    if (subproduto) {
        const impre = subproduto.subproduto.find(sub => sub.name === impressao);

          const returnpreco = impre.preco.filter((params) => params.quantidade[0] <= quantity)
          const preco = returnpreco[returnpreco.length - 1]
          
       
          return preco.quantidade[1] * quantity

    }

  }
  const valueEnc = (quantidade)=>{


      const enc = produtos.find(enc => enc.name === "ENCADERNAÇÃO")
      if(enc){
        const saveEnc = enc.subproduto.find(sub => sub.toast >= quantidade)
        const returnpreco = saveEnc.preco.filter((params) => params.quantidade[0] <= quantidade)
        const preco = returnpreco[returnpreco.length - 1]

        return preco.quantidade[1]
      }

      return null
  }
  
  return (
    <Grid container spacing={3} style={{marginTop: '10px'}}>
      <Grid item xs={3}>
        <h4>Frente</h4>
        <ButtonGroup orientation="vertical" color="primary" aria-label="Vertical outlined primary button group">
          <Button onClick={() => onSelect('Impressao frente preto e branco')}> Preto & B - R${valorProduto('Preto *F').toFixed(2)}</Button>
          <Button onClick={() => onSelect('Impressao frente colorido')}>Colorido - R$ {valorProduto('Colorido *F').toFixed(2)}</Button>
        </ButtonGroup>

        <h4>Frente e Verso</h4>
        <ButtonGroup orientation="vertical" color="primary" aria-label="Vertical outlined primary button group">
          <Button onClick={() => onSelect('Frente e Verso P&B')}>Frente e Verso P&B - R$ {valorProduto('Preto *FV').toFixed(2)}</Button>
          <Button onClick={() => onSelect('Frente e Verso Colorido')}>Frente e Verso Colorido - R$ {valorProduto('Colorido *FV').toFixed(2)}</Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={1}>
        <div style={{ height: '100%', borderLeft: '10px solid #223641', }}></div>
      </Grid>

      <Grid item xs={3}>
        <h5> Com Encadernação</h5>
        <ButtonGroup orientation="vertical" color='primary' aria-label="Vertical outlined primary button group" >
          <Button onClick={() => onSelect('Preto *F')}>Preto & B - Frente R$ { (valueEnc(quantity)+ valorProduto('Preto *F')).toFixed(2)}</Button>
          <Button style={{marginTop: '10px'}}onClick={() => onSelect('Não encadernado')}>Colorido - Frente R$ { (valueEnc(quantity)+ valorProduto('Colorido *F')).toFixed(2)}</Button>
      
          <Button onClick={() => onSelect('Encadernado')}>Preto & B - Frente e Verso R$ { (valueEnc(quantity)+ valorProduto('Preto *FV')).toFixed(2)}</Button>
          <Button onClick={() => onSelect('Não encadernado')}>Colorido - Frente e Verso R$ { (valueEnc(quantity)+ valorProduto('Colorido *FV')).toFixed(2)}</Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={1}>
        <div style={{ height: '100%', borderLeft: '10px solid #223641', }}></div>
      </Grid>
      
      <Grid item xs={3}>
        <h5> Outros</h5>
        <ButtonGroup orientation="vertical" color='primary' aria-label="Vertical outlined primary button group" >
          <Button onClick={() => onSelect('Preto *F')}>Preto & B - Frente R$ { (valueEnc(quantity)+ valorProduto('Preto *F')).toFixed(2)}</Button>
          <Button style={{marginTop: '10px'}}onClick={() => onSelect('Não encadernado')}>Colorido - Frente R$ { (valueEnc(quantity)+ valorProduto('Colorido *F')).toFixed(2)}</Button>
      
          <Button onClick={() => onSelect('Encadernado')}>Preto & B - Frente e Verso R$ { (valueEnc(quantity)+ valorProduto('Preto *FV')).toFixed(2)}</Button>
          <Button onClick={() => onSelect('Não encadernado')}>Colorido - Frente e Verso R$ { (valueEnc(quantity)+ valorProduto('Colorido *FV')).toFixed(2)}</Button>
        </ButtonGroup>
      </Grid>
     
    </Grid>
  );
}

export default PrintOptions;
