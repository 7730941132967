export function getFileFormat(fileName) {
    // Encontra a última ocorrência do ponto na string
    const lastDotIndex = fileName.lastIndexOf('.');
    
    // Verifica se um ponto foi encontrado na string
    if (lastDotIndex !== -1) {
      // Extrai a extensão do arquivo
      const fileFormat = fileName.slice(lastDotIndex + 1);
      return fileFormat.toUpperCase(); // Retorna a extensão do arquivo em minúsculas
    } else {
      // Caso não seja encontrado nenhum ponto na string, retorna uma string vazia
      return '';
    }
  }