
import { useEffect, useState } from 'react'
import axios from '../../services/api'
import {valorTotal} from '../../components/Total Produtos/TotallProdutos'
import { useHistory } from "react-router-dom";
import { Button } from 'react-bootstrap';


const products = []

const viewProducts = (objetos) =>{

    objetos.forEach(element=>{
        if(element.nameAlfa === "LÁPIS DE COLORIR")products.push(element)
    })

    
}



function Adm (){

    const history = useHistory();

    const [vendas, setVendas] = useState([])
    const [carlos, setCarlos] = useState([])
    const [alisson, setAlisson] = useState([])
    const [lucas, setLucas] = useState([])
    const [antonio, setAntonio] = useState([])
   


    useEffect(async()=>{

        await axios.get(`admin`).then(({data})=>{

            const p = []
            const desconto = []
            const carlos = [0]
            const alisson = [0]
            const lucas = [0]
            const antonio = [0]
           
            data.map((e,index,array)=>{

                viewProducts(e.objetos)

                if(e.funcionario === "Antônio")antonio.push(valorTotal(e.objetos))
                if(e.funcionario === "Carlos")carlos.push(valorTotal(e.objetos))
                if(e.funcionario === "Alisson")alisson.push(valorTotal(e.objetos))
                if(e.funcionario === "Lucas")lucas.push(valorTotal(e.objetos))

               p.push(valorTotal(e.objetos))
               desconto.push(e.desconto)

                if(index === array.length - 1) {
                   const a = p.reduce((acc, tabela)=>{return (parseInt(acc)+ parseInt(tabela))})
                   const b = desconto.reduce((acc,tabela)=>{return (parseInt(acc)+ parseInt(tabela))})
                    const c = carlos.reduce((acc,tabela)=>{return (parseInt(acc)+ parseInt(tabela))})
                    const d = alisson.reduce((acc,tabela)=>{return (parseInt(acc)+ parseInt(tabela))})
                    const e = lucas.reduce((acc,tabela)=>{return (parseInt(acc)+ parseInt(tabela))})
                    const f = antonio.reduce((acc,tabela)=>{return (parseInt(acc)+ parseInt(tabela))})

                  



                setVendas((a - b).toFixed(2))
                setCarlos(c)
                setAlisson(d)
                setLucas(e)
                setAntonio(f)

                }
            })
         

          
            
        })
       




    },[])

    return (
        
    <>
  
    <Button onClick={()=>history.push("/users")}>users</Button  >
    <div>
        
        
        <h1> Vendas:</h1>
        <h2> R$ {vendas}</h2>
        <h2> Carlos R$ {carlos || 0} </h2>
        <h2> Antônio R$ {antonio || 0 }</h2>
        <h2> Alisson R$ {alisson || 0}</h2>
        <h2> Lucas R$  {lucas || 0}</h2>
    
    
    
    
    
    </div>
    
    </>
    
    
    )

}




export default Adm