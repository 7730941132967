import React from 'react';
import  'bootstrap/dist/css/bootstrap.min.css' ;
import './index.css';

import Home from './../pages/home'
import Pdv from './../pages/pdv'
import Pedidos from './../pages/pedidos'
import Edit from './../pages/edit'
import Clients from './../pages/Clients/index'
import Funcionario from './../pages/funcionarios/index'
import Adm from './../pages/adm/index'
import Login from './../pages/login/login'
import Menubar from '../components/MenuBar/index'
import Users from '../pages/Users';
import UploadPage from '../pages/Uploads';
import Ordem from '../pages/Ordem'
import OrdemList from '../pages/OrdemList'
import Moldes from '../pages/moldes'
import Pix from '../pages/Pix'

import {BrowserRouter,Switch} from 'react-router-dom'
import Route from "./Route";
import { AuthProvider } from './../context/Auth/AuthContext'
import { ToastContainer } from "react-toastify";



const Routes = () =>{
    return (
        
  <BrowserRouter>
  <AuthProvider>
 
    <Switch>
    <Route exact path="/login" component={Login} ></Route>
    <Menubar>
        <Route exact path="/"  component={Home} isPrivate />
        <Route exact path="/pdv" component={Pdv} isPrivate  />
        <Route exact path="/pedidos" component={Pedidos} isPrivate/>
        <Route exact path="/edit" component={Edit} isPrivate/>
        <Route exact path="/clients" component={Clients} isPrivate></Route>
        <Route exact path="/funcionarios" component={Funcionario} isPrivate></Route>
        <Route exact path="/uploadPage" component={UploadPage} isPrivate></Route>
        <Route exact path="/ordem" component={Ordem} isPrivate></Route>
        <Route exact path="/ordemList" component={OrdemList} isPrivate></Route>
        <Route exact path="/moldes" component={Moldes} isPrivate />
        <Route exact path="/pix" component={Pix} isPrivate />

        <Route exact path="/adm" component={Adm} isPrivate admin></Route>
        <Route exact path="/users" component={Users} isPrivate admin/>

        </Menubar>
    </Switch>
  
   </AuthProvider>
   </BrowserRouter>
    )
}

export default Routes;