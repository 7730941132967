import React, { useState, useRef, useEffect } from 'react';
import { Table, Collapse, Dropdown, Row, Col, Container } from 'react-bootstrap';
import {
  
    Button,
    
} from '@material-ui/core';
import { FiEdit3, FiPrinter } from 'react-icons/fi'
import { Link } from 'react-router-dom';
import Axios from '../../services/api'
import { useReactToPrint } from 'react-to-print'
import Printer from '../Print/PrintPedidos'
import moment from 'moment'
import { AuthContext } from '../../context/Auth/AuthContext'
import './style.css'
import ModalOrdem from './ModalOrdem'

function List({ pedidos, setPedidos, state }) {
    
    const [open, setOpen] = useState(state ? true : false)

    const [indice, setIndice] = useState(state? 0 : null)
    const { user } = React.useContext(AuthContext)

    const [vendedor, setVendedor] = useState(user.nome)
    const funcionarios = ["Alisson", "Antônio", "Bartô", "Carlos", "Lucas", 'Vendedor: Todos']


    const componentRef = useRef()
    const handlePrint = useReactToPrint({ content: () => componentRef.current, }, [componentRef.current])
    

    useEffect(()=>{
        setTimeout(() => {
            if(state){
                handlePrint()
                 return;}
         
            
        }, 500);

    },[])
    const vendasFilter = (i) => {
        return i.funcionario === vendedor
    }
    const vendasFil = vendedor === 'Vendedor: Todos' ? pedidos : pedidos.filter(vendasFilter)

    const pedidosSort = vendasFil.sort((a, b) => {

        if (a.createdAt.substr(0, 19) > b.createdAt.substr(0, 19)) return -1;
        if (a.createdAt.substr(0, 19) < b.createdAt.substr(0, 19)) return 1;
        return 0;
    })


    function Colapse(params) {

        const { obs, objetos, funcionario, desconto, cliente, nomeCliente, id } = params.item

        return (
            params.indice === indice ? (

                <Collapse in={params.open}>

                    <Container key={indice} fluid>
                        

                    

                        <Row>

                            <Col>Nomes</Col>
                            <Col>Quantidade</Col>
                            <Col>Preço</Col>
                        </Row>

                        {params.item.objetos.map((i, key) => (

                            <Row key={key}>

                                <Col>{i.nameAlfa} - {i.name}</Col>
                                <Col>{i.quantidade}</Col>
                                <Col>{`R$ ${i.precoAtual.toFixed(2)}`}</Col>
                            </Row>

                        ))}
                            <Row key={indice}>
                            <Col style={{display: 'flex', justifyContent: 'center'}}>
                                <Button 
                                variant="contained" 
                                onClick={async () => {
                                    await Axios.post('/vendas', {
                                        obs: obs,
                                        objetos: objetos,
                                        funcionario: funcionario,
                                        desconto: desconto,
                                        type: "venda",
                                        cliente: cliente,
                                        nomeCliente: nomeCliente

                                    }).then(async () => {
                                        await Axios.delete(`/pedidos/${id}`)
                                            .then(e => {
                                                const newPedidos = pedidos.filter(e => {
                                                    return e.id !== id
                                                })
                                                setPedidos(newPedidos)
                                                setOpen(!open)

                                            })

                                    })
                                }}
                                style={{marginRight: '15%',backgroundColor: 'green', color: 'white'}}
                                >
                                    Fechar Venda
                                </Button>
                            
                            <Link to={{
                                pathname: "/edit", state: { item: params.item }
                            }}
                            style={{marginRight: '15%'}}
                            >
                                <Button 
                                    variant='contained'
                                    style={{backgroundColor: 'cyan', color: 'black'}}
                                    >

                                        <FiEdit3 />Editar
                                </Button>
                            </Link>

                           <Button
                           variant='contained'
                           onClick={() => {
                                Axios.delete(`/pedidos/${params.item.id}`).then((response) => {
                                    Axios.get('/pedidos').then(res => { setOpen(!open); setPedidos(res.data) })
                                })
                            }}
                            style={{marginRight: '15%',backgroundColor: 'red', color: 'white'}}
                            >Cancelar
                            </Button>

                            <div style={{ display: 'none' }}>
                                <style> {"@page {size: 120mm 200mm; margin: 5mm} "}
                                    <Printer ref={componentRef} item={params.item}></Printer>
                                </style>

                            </div>
                                <Button 
                                    variant='contained' 
                                    onClick={handlePrint}>
                                        <FiPrinter /> 
                                </Button>
                                

                            </Col>
                        </Row>
                    </Container>
                </Collapse>

            ) : <div></div>)
    }
  

    return (

        <Container style={{ color: '#eee' }} fluid>


            <Row>
                <Col>Data e Hora:</Col>              
                <Col>Itens:</Col>
                <Col>Total Final:</Col>
                <Col>Observações:</Col>
                <Col>Cliente:</Col>
                <Col>
                <Dropdown>
                    <Dropdown.Toggle variant="dark" id="dropdown-basic">
                        {vendedor}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {funcionarios.map((i, key) => { return <Dropdown.Item onClick={() => setVendedor(i)} key={key}>{i}</Dropdown.Item> })}

                    </Dropdown.Menu>
                </Dropdown>
                </Col>
                <Col>Desconto:</Col>
                <Col>Produção</Col>
                
            </Row>



            {pedidosSort.map((item, indice) => {
              
                const
                    { objetos,
                        createdAt,
                        nomeCliente,
                        obs,
                        funcionario,
                        id,
                        uploads
                    } = item

                const values = Object.values(objetos).reduce((acc, tabela) => {
                    return (acc + (tabela.precoAtual * tabela.quantidade))
                }, 0)

                const date = (<>
                <div>{moment(createdAt).format('HH:mm')}</div>
                <div>{moment(createdAt).format('DD/MM/YYYY')} </div>
                </>
                )
                const desconto = parseFloat(item.desconto)

                return (
                    <Container className="hover-container" fluid
                    onClick={(e) => {
                        setIndice(indice)
                        if (e.target.localName === "div") {
                            setOpen(!open)
                        }
                    }}
                    style={{ backgroundColor: nomeCliente === "sem cliente" ? '':  "#152238"}} 
                    key={indice}
                    > 
                        <Dropdown.Divider style={{ borderColor: '#000' }} />
                        <Row  
                        
                          >
                            <Col>{date}</Col>
                            <Col>{`${objetos[0].nameAlfa} + ${objetos[0]?.name}`}</Col>
                            <Col>R$ {(values - desconto).toFixed(2)}</Col>
                            <Col className="colLimit">{obs}</Col>
                            <Col>{nomeCliente === "sem cliente" ? "" : nomeCliente}</Col>
                            <Col>{funcionario}</Col>
                            <Col> {desconto > 0 ? ` R$ ${desconto}` : ""}</Col>
                            <Col> {
                                <ModalOrdem objetos={objetos} obs={obs} id={id} funcionario={funcionario} uploads={uploads}>
                                  
                                </ModalOrdem>
                                }</Col>
                           

                        </Row>

                        <Row key={`a` + indice} >
                            <Col colSpan="8" key={`a` + indice} style={{width: '100%'}} >
                                <Colapse open={open} indice={indice} item={item}></Colapse>
                            </Col>
                        </Row>
                    </Container>
                )
            })}


        </Container>

    )
}

export default List