import React from 'react'


class Print extends React.PureComponent {

    render() {
        const item = this.props?.item
        const total = []
        function totalDesconto(){
            
            const somaTotal = total.reduce((soma, i )=> { return soma+i})

            return (
                somaTotal- item.desconto
            )
        }
        // console.log(item)
        return (
            <div>
                <h1>         Copiadora Vieira  </h1><br></br>
                                <h2> Pedido(n: )              </h2><br></br>
                <h5> Sem valor fiscal - Não é válido como recibo e como garantia <br></br>de mercadoria - Não comprova pagamento
                </h5>

                <table>
                    <thead>
                        <tr><h4>
                            <th>Descrição / Quantidade X Unitário</th>
                          
                            <th>Total</th>
                            </h4>
                        </tr>
                    </thead>
                    <tbody>
                        {item.objetos.map((i, key) => {


                            total.push(i.quantidade * i.precoAtual)
                            return (
                               
                                <tr key={key}>
                                    <h4>
                                    <td>{i.name} / {i.quantidade} X R$ {i.precoAtual.toFixed(2)}</td>
                       
                                    <td><strong>R$ {(i.quantidade * i.precoAtual).toFixed(2)}</strong></td>
                                    </h4>
                                </tr>
                               
                            
                            )
                        }
                        )
                        }

                        
                        <tr><td>___________________________________________________________</td></tr>

                        <tr><td> <h3> Total Produtos R$ {total.reduce((soma, i )=> { return soma+i})}</h3>
                            { item.desconto? <h2>Desconto R$ {item.desconto}</h2> : <div></div>}
                            <h2><strong>Total R$ {totalDesconto().toFixed(2)}</strong></h2>
                        
                        </td></tr>
                        <tr><td>___________________________________________________________</td></tr>
                        <tr>
                        <h4> Vendedor: {item.funcionario} </h4>
                        <h2> Cliente:{item.nomeCliente}</h2>
                        <h2><strong>Obs.: {item.obs}</strong></h2>
                        </tr>
                        
                        

                    </tbody>
                </table>
            </div>
        )
    }
}

export default Print