
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SettingsIcon from '@material-ui/icons/Settings';
import Fab from '@material-ui/core/Fab';
import Axios from '../../services/api'

import GetAppIcon from '@material-ui/icons/GetApp';


import { makeStyles } from '@material-ui/core/styles';


import {
    Paper,
    Grid,
    Button,
    Typography
} from '@material-ui/core';
import { UploadS3 } from '../UploadS3';




const DialogOrdem = ({ objetos, obs, id, funcionario, uploads }) => {

    const [open, setOpen] = React.useState(false);
    const [upDados, setUpDados] = useState(null);
    const [filho, setFilho] = useState([])  
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleProducao = async (id) => {

        try {


            const novoStatus = 'Produção'; // Coloque aqui o novo status desejado

            // Realiza a requisição PUT para atualizar o status da ordem
            await Axios.put(`/ordem/${id}`, { situation: novoStatus });


        } catch (error) {
            // Em caso de erro na requisição, você pode tratar o erro aqui
            console.error('Erro ao atualizar o status da ordem:', error);
        }
    }
    const handleFinalizar = async (id) => {

        try {


            const novoStatus = 'finalizado'; // Coloque aqui o novo status desejado

            // Realiza a requisição PUT para atualizar o status da ordem
            await Axios.put(`/ordem/${id}`, { situation: novoStatus });


        } catch (error) {
            // Em caso de erro na requisição, você pode tratar o erro aqui
            console.error('Erro ao atualizar o status da ordem:', error);
        }
    }

    const handleUpload = (event) => {
        const file = event.target.files[0];
        // Faça algo com o arquivo aqui, como enviar para o servidor
        console.log('Arquivo selecionado:', file);
    };

    const pegarDadosFilho = (newDados)=>{

   
        setFilho(newDados)
    
      }

      const handleModalClick = (event) => {
        // Impede que o evento de clique seja propagado para os elementos abaixo do modal
        event.stopPropagation();
      };
    return (
        <div onClick={handleModalClick}>
            <Fab color="primary" aria-label="add" onClick={() => handleClickOpen()}>
                <SettingsIcon />
            </Fab>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogContent>
                    <Grid container spacing={2}>
                        
                    <Grid item xs={6}>

                    <h4>Pedido: {id}</h4>
                    <h3>{obs}</h3>
                    <h6>Registrado por: {funcionario}</h6>

                    </Grid>

                    <Grid item>

                    <h4>Produção</h4>
                    <Button variant='contained' color=''>Enviar para produção</Button>
                    <h6>Status: {funcionario}</h6>

                    </Grid>


                    </Grid>

                    
                    <UploadS3 pegarDadosFilho={pegarDadosFilho} upDados={upDados} id={id} uploads={uploads}></UploadS3>
                    

                    <Grid container spacing={1}>

                        {objetos.map(item => (
                            <Grid item xs={6} key={item.name} style={{ border: '1px solid black', padding: '10px' }}>


                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Paper>
                                            <h5>{item.nameAlfa}</h5>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Paper>
                                            <h5>{item.name}</h5>
                                        </Paper>
                                    </Grid>
                                </Grid>


                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Paper>
                                            <Typography variant="inherit">
                                                Quantidade: {item.quantidade}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Paper>
                                            <Typography variant="caption">
                                                Preço Atual: {item.precoAtual}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                </Grid>


                                <Grid container spacing={1}>
                                   
                                    <Grid item xs>
                                        <Paper>

                                            <Typography variant="caption">
                                                {item.date}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}

                    </Grid>

                </DialogContent>


                <DialogActions>

                    <Button onClick={handleClose} color="primary" autoFocus>
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DialogOrdem;