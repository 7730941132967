import React, { useState, useEffect } from 'react';
import axios from '../../services/api';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getBackendUrl } from "../../config";
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        backgroundColor: '#333', // cor de fundo desejada
    },
    accordion: {

        backgroundColor: '#282828',
        
    },
    uploadButton: {
        color: '#fff', // cor do texto desejada
    },
    downloadButton: {
        color: '#fff', // cor do texto desejada
    },
    fileName: {
        color: '#fff', // cor do texto desejada
    },
    successMessage: {
        color: '#fff', // cor do texto desejada
    },
}));


const UploadPage = () => {


    const classes = useStyles();
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [files, setFiles] = useState([]);
    const [repload, setRepload] = useState(false)

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const response = await axios.get('/files');
                setFiles(response.data);
            } catch (error) {
                console.error('Erro ao obter lista de arquivos:', error);
            }
        };
        fetchFiles();

    }, [repload]);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    }

    const handleUpload = async () => {
        if (!selectedFile) {
            return;
        }

        setUploading(true);
        setUploadSuccess(false);

        try {
            const formData = new FormData();
            formData.append('file', selectedFile);
            await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setUploadSuccess(true);
        } catch (error) {
            console.error('Erro ao realizar upload do arquivo:', error);
        } finally {

            setUploading(false);
            setRepload(!repload)
        }
    }



    return (
        <div className={classes.root}>
            <Typography variant="h5" className={classes.fileName}>
                Upload de Arquivos
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <input
                        type="file"

                        onChange={handleFileChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloudUploadIcon />}
                        onClick={handleUpload}
                        disabled={uploading || !selectedFile}
                    >
                        {uploading ? 'Enviando...' : 'Enviar'}
                    </Button>
                </Grid>
                {uploadSuccess && (
                    <Grid item xs={12}>
                        <Typography variant="body1" color="textSecondary">
                            Arquivo enviado com sucesso!
                        </Typography>
                    </Grid>
                )}
               
                    <Accordion className={classes.accordion}>


                        <AccordionSummary

                            aria-controls="panel1a-content"
                            id="panel1a-header">

                            <Typography style={{ color: '#eee' }} variant="h6">Lista de {files.length} Arquivos</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            <Grid item xs={12}>

                                {files.map(file => (
                                    <Grid container spacing={2} alignItems="center" key={file}>
                                        <Grid item xs={6}>
                                            <Typography style={{ color: '#eee' }} variant="body1">{file}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<GetAppIcon />}
                                                href={`${getBackendUrl()}/public/${file}`} // substitui a função onClick pelo atributo href
                                                target="_blank" // Adiciona o atributo target="_blank" para abrir em nova guia
                                                rel="noopener noreferrer" // Adiciona os atributos rel="noopener noreferrer" para segurança
                                                className={classes.downloadButton}
                                            >
                                                Baixar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>

                        </AccordionDetails>


                    </Accordion>
             


            </Grid>
        </div>
    );
};

export default UploadPage;
