import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Axios from '../../services/api'
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import socket from '../../services/socket-io'
import moment from 'moment'
import GetAppIcon from '@material-ui/icons/GetApp';
import Badge from '@material-ui/core/Badge'



import DoneAllIcon from '@material-ui/icons/DoneAll';
import { getSignedUrl } from '../../components/MonitorS3';


import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Avatar,
} from '@material-ui/core';


import Box from '@material-ui/core/Box';


import DialogOrdem from './DialogOrdem';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        flexGrow: 1, // Corrigindo o valor de flexGrow para expandir corretamente
        width: '100%',
        backgroundColor: theme.palette.background.paper,

    },
}));

export default function ScrollableTabsButtonPrevent() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const [ordensDeServico, setOrdensDeServico] = useState([]);
    const [ordensFinalizados, setOrdensDeFinalizados] = useState(0)
    const [ordensFinalizadosTotal, setOrdensDeFinalizadosTotal] = useState([])


    const pendentes = ordensDeServico.filter((e) => { return e.situation === "pendente" })
    const producao = ordensDeServico.filter((e) => { return e.situation === "Produção" })


    useEffect(() => {
        const fetchOrdensDeServico = async () => {
            try {
                const statusParam = 'pendente';
                const response = await Axios.get(`/ordem?status=${statusParam}`);
                const responseFinalizados = await Axios.get(`/ordem?status=${'finalizado'}`)

                setOrdensDeFinalizados(responseFinalizados.data)
                setOrdensDeServico(response.data);
            } catch (error) {
                console.error('Erro ao buscar as ordens de serviço:', error);
            }
        };


        fetchOrdensDeServico();

        socket().on('ordemUpdate', (data) => {
            fetchOrdensDeServico();
        });
    }, []);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tableHead = () => {
        return (
            <TableHead style={{ backgroundColor: '#0075B6' }}>
                <TableRow>
                    <TableCell align="center">Informações</TableCell>
                    <TableCell align="center">Serviço</TableCell>
                    <TableCell align="center">Funcionario</TableCell>
                    <TableCell align="center"></TableCell>

                </TableRow>
            </TableHead>
        )
    }


    function isPar(numero) {
        return numero % 2 === 0;
    }

    const tableMap = (map) => {
        return (
            <>

                <Table>
                    {tableHead()}
                    <TableBody>

                        {map.map((ordem, index) => (

                            <React.Fragment key={ordem.id}>
                                <TableRow
                                    /* onClick={() =>  handleRowClick(ordem.id)}*/
                                    style={isPar(index) ? { backgroundColor: '#a6b7f1', } : { backgroundColor: '#343a4047' }}
                                >
                                    {console.log(ordem, 'ooor')}
                                    <TableCell align="center">
                                        <div>{moment(ordem.createdAt).format('HH:mm')}</div>
                                        <div>{moment(ordem.createdAt).format('DD/MM/YYYY')} </div>
                                        <div><GetAppIcon /> {ordem.uploads?.length}</div>

                                    </TableCell>
                                    <TableCell align="center"> <Typography variant="h5" style={{ textTransform: 'uppercase' }}>{ordem.servico}</Typography> </TableCell>
                                    <TableCell align="center">

                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>


                                            <Avatar style={{ margin: '15px' }} src={`https://api.cvdesk.net/public/${ordem.number}.jpg`}></Avatar>
                                            <div> {ordem.funcionario}</div>
                                        </div>

                                    </TableCell>
                                    <TableCell align="center">

                                        <DialogOrdem
                                            ordem={ordem}
                                            getSignedUrl={getSignedUrl}


                                        ></DialogOrdem>

                                    </TableCell>
                                </TableRow>
                            </React.Fragment>


                        ))}
                    </TableBody>
                </Table>

            </>
        )
    }

    const fetchOrdensFinalizadas = async () => {
        try {

            const response = await Axios.get(`/ordem?status=${'finalizados'}`);
            const res = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setOrdensDeFinalizadosTotal(res);
        } catch (error) {
            console.error('Erro ao buscar as ordens de serviço:', error);
        }
    }

    return (
        <div className={classes.root}>
            <AppBar position="relative">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    scrollButtons="off"
                    aria-label="scrollable prevent tabs example"
                >
                    <Tab icon={
                        <Badge badgeContent={pendentes.length} color="secondary">
                            <AssignmentIcon />
                        </Badge>
                    } aria-label="phone" {...a11yProps(0)} label='Pendentes' />

                    <Tab icon={
                        <Badge badgeContent={producao.length} color="secondary">
                            <SettingsIcon />
                        </Badge>


                    } aria-label="favorite" {...a11yProps(1)} label='Em Produção' />

                    <Tab icon={
                        <Badge badgeContent={ordensFinalizados} color="secondary">
                    <DoneAllIcon />
                    </Badge>
                    } aria-label="person" {...a11yProps(2)} label='Finalizados' />
                </Tabs>
            </AppBar>
            <div value={value} index={0}>
                {value === 0 && (
                    tableMap(pendentes)
                )}
            </div>
            <div value={value} index={1}>
                {value === 1 && (
                    tableMap(producao)
                )}
            </div>
            <div value={value} index={2}>
                {value === 2 && (
                    <>
                        {ordensFinalizadosTotal.length === 0 && (
                            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', margin: '3px' }}>
                                <Typography style={{ margin: '6px' }} variant="h6">{ordensFinalizados + ' ordens'}</Typography>
                                <Button variant='contained' color='primary' onClick={() => fetchOrdensFinalizadas()}>CARREGAR</Button>
                            </div>
                        )}
                        {ordensFinalizadosTotal.length > 0 && tableMap(ordensFinalizadosTotal)}
                    </>
                )}
            </div>
        </div>
    );
}
