import React, { useState } from 'react';
import {
  Grid,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select, Checkbox,
  FormGroup,
  Button,
  Input,
  TextField,
  Typography,
  Paper,
  MenuItem,
  Card,
  CardContent,
  LinearProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Axios from '../../services/api'
import { AuthContext } from '../../context/Auth/AuthContext'
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import ModalTamanho from '../../components/Modal/ModalTamanho'
import { UploadS3 } from '../../components/UploadS3';


const checkboxStyle = {
  display: 'inline',
  margin: '30px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};





const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(3),
    minWidth: '60%',
    backgroundColor: '#EAEAEA',
    borderRadius: '10px',
    background: '#eee'
  },
  select: {
    margin: theme.spacing(2),
    minWidth: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  formContainer: {

    padding: theme.spacing(3),
    borderRadius: 8,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    maxWidth: 400,
  },

  fileInput: {
    display: 'none',
    marginLeft: theme.spacing(2),
  },
  fileInputLabel: {

    padding: theme.spacing(1, 2),
    borderRadius: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    progress: {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      width: '100%',
      height: '800px',
    },

  },
  card: {
    maxWidth: 150,
    maxHeight: 100,
    margin: 'auto',
    marginTop: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    padding: theme.spacing(1),
  },
  input: {
    display: 'none',
  },




}));

const FormularioServico = () => {
  const classes = useStyles();
  const [servico, setServico] = useState('');
  const [especificacoesCaneca, setEspecificacoesCaneca] = useState({
    simples: false,
    magica: false,
    comGlitter: false,
  });
  const [especificacoesAdesivo, setEspecificacoesAdesivo] = useState({
    tamanho: false,
  });
  const [upDados, setUpDados] = useState(null);
  const [observations, setObservations] = useState('');
  const [nome, setNome] = useState('');
  const { user } = React.useContext(AuthContext)
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [filho, setFilho] = useState([])


  const handleServicoChange = (event) => {
    setServico(event.target.value);
  };

  const handleCanecaEspecificacoesChange = (event) => {
    setEspecificacoesCaneca({ ...especificacoesCaneca, [event.target.name]: event.target.checked });
  };

  const handleAdesivoEspecificacoesChange = (event) => {

    setEspecificacoesAdesivo({ ...especificacoesAdesivo, "tamanho": event });
  };




  
  const handleObservationsChange = (event) => {
    setObservations(event.target.value);
  };



  const handleModalClose = () => {
    // Lógica para lidar com o fechamento do modal, se necessário
    console.log('Modal fechado.');
  };

  const handleLogicComplete = (dataFromModal) => {
    // Lógica para lidar com os dados resultantes da lógica do modal

    handleAdesivoEspecificacoesChange(dataFromModal); // Atualiza o estado do componente pai com os dados do modal, se necessário
  };
  const pegarDadosFilho = (newDados)=>{

   
    setFilho(newDados)

  }
  

  const handleSubmit = async () => {

    if (servico == '') { return alert('serviço obrigatorio') }
    if(filho === 'pending') { return alert('upload em progresso') }

    
    setLoading(true);



    const VerifyAtributo = () => {

      if (servico === 'caneca') return especificacoesCaneca
      if (servico === 'adesivo') return especificacoesAdesivo
    }

    const data = {
      servico: servico,
      atributo: VerifyAtributo(),
      obs: observations,
      cliente: nome,
      funcionario: user.nome,
      filesUpload: filho
    };


    if (servico === 'caneca') {

      delete data.especificacoesAdesivo;
    }
    if (servico === 'adesivo') {

      delete data.especificacoesCaneca;
    }



    const submitOrdem = async () => {

      
     

      await Axios.post('/ordem', data)
        .then((response) => {

          setTimeout(() => {
            // Termine o loading após a chamada assíncrona ser concluída
            setLoading(false);
            history.push('/ordemList');
          }, 1000);


        })
        .catch((error) => {
          // Manipule os erros, caso ocorram
          alert('Erro ao enviar dados para o backend:', error);
        });

    }

   
    submitOrdem()

  };


//    setUpDados('dados')





  
  return (

    <div >
          
      {!loading && (

        <Grid container direction="column" alignItems="center" spacing={2}>
          <Paper className={classes.paper}>
            <Grid item>

              <FormControl component="fieldset" style={checkboxStyle}>

              </FormControl>
              <Typography variant="h6" style={checkboxStyle}>Serviço</Typography >
              <InputLabel id="demo-simple-select-label"></InputLabel>

              <Select className={classes.select}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={servico}
                onChange={handleServicoChange}
              >
                <MenuItem value={'caneca'}>Caneca</MenuItem>
                <MenuItem value={'adesivo'}>Adesivo</MenuItem>
                <MenuItem value={'banner'}>Banner</MenuItem>

              </Select>

            </Grid>

            {servico === 'caneca' && (

              <Grid item>
                <FormGroup style={checkboxStyle}>

                  <FormControlLabel
                    control={<Checkbox checked={especificacoesCaneca.simples} onChange={handleCanecaEspecificacoesChange} name="simples" />}
                    label={<Typography variant="body1">Simples</Typography>}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={especificacoesCaneca.magica} onChange={handleCanecaEspecificacoesChange} name="magica" />}
                    label="Mágica"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={especificacoesCaneca.comGlitter} onChange={handleCanecaEspecificacoesChange} name="comGlitter" />}
                    label="Com Glitter"
                  />
                </FormGroup>
              </Grid>

            )}

            {servico === 'adesivo' && (
              <Grid item>
                <FormGroup style={checkboxStyle}>



                  <ModalTamanho onClose={handleModalClose} onLogicComplete={handleLogicComplete}></ModalTamanho>
                  {especificacoesAdesivo.tamanho}



                </FormGroup>
              </Grid>
            )}

            <UploadS3 pegarDadosFilho={pegarDadosFilho} upDados={upDados}></UploadS3>
     



            {/* Adding the Observations field */}
            <Grid item>
              <TextField
                label="Observações"
                variant="outlined"
                value={observations}
                onChange={handleObservationsChange}
                multiline
                minRows={4}
                fullWidth
              />
            </Grid>


            <form>
              <TextField
                label="Cliente"
                value={nome}
                onChange={(event) => { setNome(event.target.value) }}
                variant="outlined"
                margin="normal"
                fullWidth
              />

            </form>

            <Grid item>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Enviar
              </Button>

            </Grid>
          </Paper>

        </Grid>

      )}

      <div className={classes.root}>
        {loading && (
          <div className={classes.progress}>
            <CircularProgress size={120} color='secondary' />
          </div>

        )}
      </div>
    </div>


  );
};

export default FormularioServico;
