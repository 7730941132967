import React from 'react';


import AbaComponent from './AbaComponent'



const OrdensDeServico = () => {
    

   

    return (
        <React.Fragment>
            <AbaComponent   
        
            >

                
            </AbaComponent>
          
         

        </React.Fragment>

    );
};

export default OrdensDeServico;
