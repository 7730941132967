import openSocket from "socket.io-client";
import { getBackendUrl } from "../config"; 




function socket() {
    return openSocket(getBackendUrl())

}


export default socket;