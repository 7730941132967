import Routes from './routes'
import 'react-toastify/dist/ReactToastify.css';
require('dotenv').config();


const  App = () => {
    return (
        <Routes />
    
        )
}

export default App