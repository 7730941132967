import { Button, Modal } from 'react-bootstrap';
import React, { useState, useRef, useEffect } from 'react'

function Modalobs({ obs, setObs }) {


  const [show, setShow] = useState(false)


  const handleClose = () => setShow(false)
  function handleShow() { setShow(true) }
  const mudarValor = params => setObs(params.target.value)

  const inputRef = useRef()


  
  

  useEffect(() => {
    document.addEventListener("keyup",(e)=>{

      e.preventDefault()
      if(e.key === "Alt" && setObs ){show === true ?  handleClose() :handleShow()}


    })
    if (show) { inputRef.current.focus() }

  })

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        OBS: -  Alt 
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Observação Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1"></label>
            <textarea className="form-control" id="exampleFormControlTextarea1" rows="15" onChange={mudarValor} ref={inputRef}>{obs}</textarea>

          </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Salvar
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Modalobs